import React from "react";
import GrundfosIcon from "../icons/GrundfosIcon";
import SkfIcon from "../icons/SkfIcon";

const PartnerLogos = ({className = ''}) => {
    return (
        <div className={`partner-logo-container ${className}`}>
            <a href="https://www.grundfos.com" title="Grundfos" target="_blank" rel="nofollow" className="partner-logo-container__logo partner-logo-container__logo_grundfos">
                <GrundfosIcon/>
            </a>
            <a href="https://www.skf.com" title="SKF" target="_blank" rel="nofollow" className="partner-logo-container__logo partner-logo-container__logo_skf">
                <SkfIcon/>
            </a>
        </div>
    );
};

export default PartnerLogos;