import * as React from "react";

export default function GrundfosIcon() {
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 794 508">
            <defs>
                <style>{`
                    .cls-1 {
                    fill: #1b3866;
                }

                    .cls-1, .cls-2 {
                    stroke - width: 0px;
                }

                    .cls-3 {
                    font - family: CeraPro-Regular, 'Cera Pro';
                    font-size: 90px;
                }

                    .cls-4 {
                    letter - spacing: -.02em;
                }

                    .cls-5 {
                    letter - spacing: -.08em;
                }

                    .cls-6 {
                    letter - spacing: -.02em;
                }

                    .cls-7, .cls-2 {
                    fill: #fff;
                }

                    .cls-8 {
                    letter - spacing: -.02em;
                }

                    .cls-9 {
                    letter - spacing: -.07em;
                }

                    .cls-10 {
                    font - family: CeraPro-Black, 'Cera Pro';
                    font-size: 74.22px;
                    font-weight: 800;
                }

                    .cls-11 {
                    letter - spacing: 0em;
                }

                    .cls-12 {
                    letter - spacing: 0em;
                }
                `}</style>
            </defs>
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <rect className="cls-1" width="794" height="508"/>
                    <g>
                        <path className="cls-2"
                              d="M316.94,426.18v27.46l-25.88-27.46h-19.54v44.89h12.68v-26.94l26.41,26.94h19.01v-44.89h-12.68ZM150.58,435.16h10.03c5.81,0,8.98,2.64,8.98,7.39,0,6.34-2.64,8.45-11.62,8.45l12.68,20.07h21.13l-13.2-19.01c2.11,0,10.56-1.58,10.56-13.2,0-12.68-12.68-12.68-21.13-12.68h-36.97v44.89h19.01v-35.91h.53ZM239.3,426.18v25.35c0,4.23-1.06,7.39-2.64,8.98s-4.23,2.64-7.39,2.64-5.81-.53-7.39-2.64-2.64-4.75-2.64-8.98v-25.35h-19.01v26.41c0,7.39,1.58,10.03,4.75,13.2,4.75,4.75,13.73,6.87,24.82,6.87s19.54-2.11,24.82-6.87c3.17-3.17,4.75-5.81,4.75-13.2v-26.41h-20.07ZM374.51,426.18h-30.63v44.89h30.63c22.18,0,32.74-4.75,32.74-22.71s-10.03-22.18-32.74-22.18ZM372.39,462.09h-10.03v-26.94h10.03c10.03,0,14.79,4.23,14.79,13.2.53,8.98-4.75,13.73-14.79,13.73ZM509.71,424.59c-24.82,0-35.39,8.98-35.39,23.77,0,16.37,10.56,24.29,35.39,24.29s35.39-7.92,35.39-24.29c-.53-14.26-11.09-23.77-35.39-23.77ZM509.18,463.15c-10.03,0-14.79-4.75-14.79-14.79s4.75-14.79,14.79-14.79,14.79,4.75,14.79,14.79c0,10.56-4.23,14.79-14.79,14.79ZM438.41,435.69h26.41l5.81-9.51h-51.76v44.89h19.54v-14.79h21.65l5.81-9.51h-27.46v-11.09ZM99.87,449.42v13.2s-5.28.53-7.92.53c-14.79,0-16.9-7.92-16.9-14.26,0-9.51,5.81-14.26,17.96-14.26,7.92,0,12.15.53,17.96,1.58l2.64.53,5.81-10.03c-13.2-1.58-21.65-1.58-29.05-1.58-24.29,0-35.39,7.92-35.39,24.29s11.09,23.24,35.39,23.24c10.56,0,20.6-.53,28.52-2.64v-20.07s-19.01-.53-19.01-.53ZM591.04,443.08c-8.98-1.58-14.26-1.58-14.26-5.28s8.98-4.23,14.79-4.23c3.17,0,10.03.53,14.79,1.58l5.28-8.98c-3.7-.53-14.26-1.58-25.35-1.58-20.07,0-33.27,3.7-33.27,13.2,0,10.56,11.09,12.15,22.18,14.26,8.45,1.58,14.26,1.58,14.26,5.81,0,4.75-7.39,5.28-14.79,5.28-2.11,0-8.45-.53-16.37-2.11l-6.87,10.56c8.45,1.06,12.15,1.58,27.99,1.58,22.71,0,33.27-4.23,33.27-15.32s-11.09-13.2-21.65-14.79ZM664.98,471.07h-24.29l33.8-33.8s2.11,1.06,6.34,5.28c4.75,4.23,5.81,6.34,5.81,6.34l-21.65,22.18Z"/>
                        <path className="cls-2"
                              d="M653.89,445.19c-12.68-15.32-16.37-22.18-19.54-30.63-2.11-5.28-4.75-15.32.53-20.6,4.75-4.75,13.73-3.17,23.24,1.06,10.56,4.75,22.71,13.73,34.33,24.29l38.03-38.03v24.29l-34.86,34.86s-3.7-4.23-5.81-5.81c-20.6-20.07-39.61-30.1-44.36-25.35-6.34,6.34,13.2,29.05,13.73,30.1,0,0-5.28,5.81-5.28,5.81ZM731,471.07h-13.73s-1.58-4.75-6.34-12.15c-4.75-7.39-8.98-11.62-8.98-11.62l8.98-8.98c3.7,4.75,7.39,9.51,11.09,15.32,3.7,5.81,6.87,11.62,8.98,17.43Z"/>
                    </g>
                    <text className="cls-7" transform="translate(54.98 112.06)">
                        <tspan className="cls-3">
                            <tspan x="0" y="0">G</tspan>
                            <tspan className="cls-6" x="67.14" y="0">R</tspan>
                            <tspan x="121.32" y="0">UND</tspan>
                            <tspan className="cls-11" x="309.51" y="0">F</tspan>
                            <tspan x="356.22" y="0">OS</tspan>
                        </tspan>
                        <tspan className="cls-10">
                            <tspan className="cls-4" x="0" y="78">V</tspan>
                            <tspan x="47.42" y="78">OLI</tspan>
                            <tspan className="cls-9" x="167.28" y="78">TA</tspan>
                            <tspan x="248.7" y="78">TUD</tspan>
                        </tspan>
                        <tspan className="cls-10">
                            <tspan x="0" y="156">HOOLD</tspan>
                            <tspan className="cls-12" x="259.16" y="156">U</tspan>
                            <tspan x="311.33" y="156">S</tspan>
                            <tspan className="cls-5" x="352.9" y="156">P</tspan>
                            <tspan x="392.01" y="156">A</tspan>
                            <tspan className="cls-8" x="440.92" y="156">R</tspan>
                            <tspan x="487.45" y="156">TNER</tspan>
                        </tspan>
                    </text>
                    <rect className="cls-2" y="334" width="794" height="3"/>
                </g>
            </g>
        </svg>
    );
}