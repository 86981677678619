import * as React from "react";

export default function Reward() {
    return (
        <svg width="188" height="188" viewBox="0 0 188 188" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_628_828)">
                <path d="M93.9958 188C42.1677 188 0 145.832 0 93.9958C0 42.1593 42.1677 0 93.9958 0C145.824 0 187.992 42.1677 187.992 93.9958C187.992 145.824 145.824 187.992 93.9958 187.992V188ZM93.9958 4.8094C44.8183 4.8094 4.8094 44.8183 4.8094 93.9958C4.8094 143.173 44.8183 183.191 94.0042 183.191C143.19 183.191 183.199 143.182 183.199 93.9958C183.199 44.8099 143.182 4.8094 93.9958 4.8094Z" fill="url(#paint0_linear_628_828)"/>
                <path d="M93.9958 1.15859C145.191 1.15859 186.841 42.8095 186.841 94.0042C186.841 145.199 145.191 186.85 93.9958 186.85C42.8012 186.85 1.15859 145.191 1.15859 93.9958C1.15859 42.8012 42.8012 1.15859 93.9958 1.15859ZM93.9958 184.349C143.815 184.349 184.341 143.815 184.341 94.0042C184.341 44.1931 143.815 3.65081 93.9958 3.65081C44.1765 3.65081 3.65081 44.1765 3.65081 93.9958C3.65081 143.815 44.1848 184.341 93.9958 184.341M93.9958 0C42.1677 0 0 42.1677 0 93.9958C0 145.824 42.1677 187.992 93.9958 187.992C145.824 187.992 187.992 145.824 187.992 93.9958C187.992 42.1677 145.832 0 93.9958 0ZM93.9958 183.191C44.8183 183.191 4.80106 143.182 4.80106 93.9958C4.80106 44.8099 44.8183 4.8094 93.9958 4.8094C143.173 4.8094 183.191 44.8183 183.191 94.0042C183.191 143.19 143.182 183.199 93.9958 183.199V183.191Z" fill="url(#paint1_linear_628_828)"/>
                <path d="M38.9918 132.429C38.2083 132.429 37.4414 132.046 36.983 131.346C29.6981 120.218 25.8389 107.307 25.8389 94.0042C25.8389 79.551 30.5649 65.1811 39.1418 53.5452C39.9337 52.4783 41.434 52.2532 42.5009 53.0368C43.5678 53.8286 43.7928 55.3289 43.0093 56.3958C35.0326 67.2066 30.6399 80.5595 30.6399 93.9958C30.6399 106.357 34.2241 118.36 40.9922 128.704C41.7174 129.812 41.409 131.304 40.3004 132.029C39.892 132.296 39.4335 132.421 38.9834 132.421L38.9918 132.429Z" fill="url(#paint2_linear_628_828)"/>
                <path d="M147.032 132.43C146.541 132.43 146.04 132.279 145.607 131.963C144.54 131.171 144.315 129.671 145.099 128.604C153.075 117.793 157.468 104.44 157.468 91.0037C157.468 78.6426 153.884 66.6399 147.116 56.296C146.391 55.1874 146.699 53.6954 147.808 52.9702C148.916 52.2451 150.408 52.5535 151.133 53.6621C158.418 64.7895 162.277 77.7007 162.277 91.0037C162.277 105.457 157.551 119.827 148.974 131.454C148.499 132.096 147.774 132.43 147.041 132.43H147.032Z" fill="url(#paint3_linear_628_828)"/>
                <path d="M149.125 53.7202C149.55 53.7202 149.941 53.9286 150.166 54.287C157.335 65.2228 161.119 77.9173 161.119 90.9952C161.119 105.207 156.476 119.326 148.041 130.762C147.799 131.087 147.441 131.271 147.032 131.271C146.766 131.271 146.507 131.187 146.291 131.029C145.74 130.621 145.615 129.837 146.024 129.287C154.142 118.276 158.618 104.682 158.618 90.9952C158.618 78.409 154.976 66.1813 148.074 55.654C147.891 55.3789 147.833 55.0455 147.899 54.7205C147.966 54.3954 148.158 54.112 148.441 53.9286C148.641 53.7952 148.883 53.7202 149.125 53.7202ZM149.125 52.5616C148.674 52.5616 148.216 52.6867 147.808 52.9534C146.699 53.6786 146.391 55.1706 147.116 56.2791C153.892 66.6231 157.468 78.6258 157.468 90.9868C157.468 104.423 153.075 117.776 145.099 128.587C144.315 129.654 144.54 131.162 145.607 131.946C146.04 132.263 146.532 132.413 147.032 132.413C147.766 132.413 148.499 132.071 148.966 131.437C157.543 119.802 162.269 105.44 162.269 90.9868C162.269 77.6839 158.418 64.7727 151.125 53.6452C150.667 52.9451 149.9 52.5616 149.116 52.5616H149.125Z" fill="url(#paint4_linear_628_828)"/>
                <path d="M53.6363 67.4733C53.6363 65.4895 54.4364 63.9808 56.0368 62.9556L67.3726 54.312C68.1395 53.6702 69.0063 53.3535 69.9649 53.3535C71.3735 53.3535 72.6572 53.7369 73.8074 54.5037C74.966 55.2706 75.5411 56.2291 75.5411 57.3877V119.843C75.5411 121.06 74.916 122.027 73.6657 122.727C72.4154 123.436 70.9568 123.786 69.2981 123.786C67.6394 123.786 66.0974 123.436 64.8721 122.727C63.6552 122.019 63.0467 121.06 63.0467 119.843V68.5319L59.971 71.6075C59.3292 72.1827 58.5624 72.4744 57.6622 72.4744C56.5119 72.4744 55.5534 71.9659 54.7782 70.9407C54.0114 69.9155 53.6279 68.7652 53.6279 67.4816L53.6363 67.4733Z" fill="url(#paint5_linear_628_828)"/>
                <path d="M84.4766 102.356V75.5501C84.4766 67.9234 86.5103 62.3555 90.5779 58.8297C94.6455 55.3039 100.105 53.5452 106.965 53.5452C113.825 53.5452 119.293 55.3039 123.394 58.8297C127.494 62.3555 129.545 67.9234 129.545 75.5501V102.356C129.545 109.983 127.494 115.551 123.394 119.076C119.293 122.602 113.816 124.361 106.965 124.361C100.113 124.361 94.6455 122.602 90.5779 119.076C86.5103 115.559 84.4766 109.983 84.4766 102.356ZM96.971 102.356C96.971 109.724 100.297 113.408 106.965 113.408C113.633 113.408 117.05 109.724 117.05 102.356V75.5501C117.05 68.1818 113.691 64.4976 106.965 64.4976C100.238 64.4976 96.971 68.1818 96.971 75.5501V102.356Z" fill="url(#paint6_linear_628_828)"/>
                <path d="M69.2985 124.378C67.4648 124.378 65.8811 123.994 64.5725 123.244C63.1638 122.427 62.4553 121.285 62.4553 119.843V69.9655L60.3966 72.0243C59.6297 72.7161 58.7128 73.0662 57.6709 73.0662C56.329 73.0662 55.1954 72.4661 54.3119 71.2908C53.47 70.1655 53.0449 68.8736 53.0449 67.4733C53.0449 65.2811 53.9451 63.5891 55.7205 62.4555L67.023 53.8369C67.8815 53.1284 68.8734 52.7534 69.982 52.7534C71.5073 52.7534 72.916 53.1785 74.1579 54.012C75.4832 54.8955 76.15 56.0291 76.15 57.3877V119.843C76.15 121.285 75.4165 122.427 73.9662 123.244C72.6409 123.994 71.0656 124.378 69.3069 124.378H69.2985ZM63.6473 67.0982V119.843C63.6473 120.843 64.1474 121.619 65.1726 122.21C66.2895 122.861 67.6815 123.186 69.2985 123.186C70.9156 123.186 72.2242 122.861 73.3744 122.21C74.433 121.619 74.9498 120.843 74.9498 119.843V57.3877C74.9498 56.4375 74.4663 55.654 73.4828 55.0039C72.4409 54.3037 71.2573 53.9536 69.9737 53.9536C69.1485 53.9536 68.4233 54.2203 67.7649 54.7788L56.404 63.4391C54.9287 64.3809 54.2369 65.6979 54.2369 67.4816C54.2369 68.6402 54.5786 69.6571 55.2704 70.5823C55.9289 71.4575 56.7124 71.8826 57.6793 71.8826C58.4294 71.8826 59.0546 71.6492 59.588 71.1741L63.6556 67.1065L63.6473 67.0982Z" fill="url(#paint7_linear_628_828)"/>
                <path d="M106.965 124.953C99.9888 124.953 94.3459 123.127 90.1949 119.518C86.0107 115.901 83.8936 110.124 83.8936 102.348V75.5417C83.8936 67.7734 86.0107 61.9971 90.1949 58.3713C94.3542 54.7705 99.9971 52.9367 106.965 52.9367C113.934 52.9367 119.585 54.7621 123.786 58.3629C128.003 61.9887 130.137 67.765 130.137 75.5334V102.339C130.137 110.108 127.995 115.884 123.786 119.51C119.585 123.111 113.934 124.936 106.965 124.936V124.953ZM106.965 54.137C100.289 54.137 94.9043 55.8624 90.9701 59.2715C87.0609 62.6556 85.0771 68.1318 85.0771 75.5417V102.348C85.0771 109.758 87.0609 115.234 90.9701 118.618C94.9043 122.027 100.281 123.761 106.965 123.761C113.65 123.761 119.035 122.035 123.002 118.618C126.953 115.226 128.945 109.758 128.945 102.348V75.5417C128.945 68.1401 126.945 62.6639 123.002 59.2715C119.035 55.8624 113.642 54.1287 106.965 54.1287V54.137ZM106.965 114C99.9388 114 96.3797 110.083 96.3797 102.356V75.5501C96.3797 67.8234 99.9388 63.9058 106.965 63.9058C113.992 63.9058 117.651 67.8234 117.651 75.5501V102.356C117.651 110.083 114.059 114 106.965 114ZM106.965 65.0894C100.639 65.0894 97.5633 68.5068 97.5633 75.5417V102.348C97.5633 109.383 100.639 112.8 106.965 112.8C113.292 112.8 116.459 109.383 116.459 102.348V75.5417C116.459 68.5068 113.358 65.0894 106.965 65.0894Z" fill="url(#paint8_linear_628_828)"/>
                <path d="M48.8446 46.2853L44.0186 39.5921L48.5196 36.3497L49.4114 37.5833L46.2774 39.8422L47.2609 41.2008L49.5614 39.5421L50.4033 40.709L48.1028 42.3677L49.3197 44.0598L52.5788 41.7092L53.4706 42.9512L48.8446 46.2853Z" fill="url(#paint9_linear_628_828)"/>
                <path d="M54.5706 42.276L50.3613 35.1745L51.8117 34.3159L55.2457 40.1006L58.3548 38.2585L59.1299 39.5671L54.5706 42.2677V42.276Z" fill="url(#paint10_linear_628_828)"/>
                <path d="M60.0967 39.0753L56.5459 31.6237L61.5553 29.2398L62.2138 30.6151L58.7297 32.2738L59.4465 33.7825L62.0138 32.5655L62.6306 33.8658L60.0717 35.0828L60.9719 36.9665L64.5977 35.2411L65.2562 36.6164L60.1134 39.067L60.0967 39.0753Z" fill="url(#paint11_linear_628_828)"/>
                <path d="M73.2501 33.6991L71.333 34.3826L66.557 31.5153L67.999 35.5662L66.4069 36.133L63.6396 28.3563L65.2317 27.7895L66.5153 31.3903L68.2073 26.7309L70.1078 26.0558L68.4324 30.7818L73.2584 33.6991H73.2501Z" fill="url(#paint12_linear_628_828)"/>
                <path d="M75.876 33.0907L74.2923 26.5559L71.7334 27.1727L71.375 25.689L78.1515 24.047L78.5099 25.5307L75.9343 26.1558L77.518 32.6906L75.876 33.0907Z" fill="url(#paint13_linear_628_828)"/>
                <path d="M87.8954 30.9819L86.0116 31.2403L83.5028 28.6397L82.2775 28.8064L82.6692 31.6904L80.9939 31.9154L79.877 23.7386L82.9526 23.3218C83.8112 23.2051 84.553 23.3552 85.1698 23.7636C85.7866 24.1804 86.1533 24.7722 86.2617 25.5557C86.3284 26.0641 86.2617 26.5475 86.07 26.9893C85.8783 27.4311 85.5615 27.7978 85.1364 28.0812L87.8871 30.9735L87.8954 30.9819ZM81.7524 24.9889L82.0691 27.3311L83.4694 27.1393C83.8612 27.0893 84.1446 26.9393 84.3363 26.6892C84.528 26.4475 84.5947 26.1475 84.5447 25.789C84.4946 25.439 84.3446 25.1806 84.0862 24.9889C83.8278 24.8055 83.5194 24.7388 83.1443 24.7888L81.744 24.9805L81.7524 24.9889Z" fill="url(#paint14_linear_628_828)"/>
                <path d="M88.8038 31.0152L88.3203 22.78L90.004 22.68L90.4875 30.9152L88.8038 31.0152Z" fill="url(#paint15_linear_628_828)"/>
                <path d="M99.9808 22.58L100.531 22.5967L100.273 30.9902L98.5888 30.9402L98.7222 26.5392L96.5634 29.0731L96.0133 29.0564L94.0212 26.3892L93.8878 30.7902L92.2041 30.7401L92.4625 22.3466L93.0126 22.3633L96.355 26.931L99.9808 22.58Z" fill="url(#paint16_linear_628_828)"/>
                <path d="M107.191 32.0071L106.899 30.8735L103.565 30.3651L102.948 31.3569L101.156 31.0819L106.074 23.3302L106.632 23.4135L109 32.2738L107.208 31.9988L107.191 32.0071ZM106.524 29.4149L105.857 26.4892L104.349 29.0814L106.524 29.4149Z" fill="url(#paint17_linear_628_828)"/>
                <path d="M109.674 30.7568L111.016 30.1984C111.383 31.1152 111.942 31.6737 112.683 31.8654C113.042 31.9571 113.333 31.9488 113.575 31.8404C113.817 31.732 113.975 31.5487 114.042 31.2653C114.1 31.0402 114.075 30.8485 113.967 30.6901C113.859 30.5318 113.634 30.3067 113.275 30.015L112.517 29.4065C111.375 28.498 110.925 27.5728 111.166 26.6059C111.341 25.9308 111.733 25.4473 112.342 25.1556C112.95 24.8722 113.634 24.8222 114.4 25.0222C115.001 25.1722 115.484 25.4473 115.876 25.8307C116.259 26.2141 116.518 26.6809 116.659 27.2144L115.326 27.7895C115.067 27.0727 114.642 26.6309 114.034 26.4809C113.742 26.4059 113.484 26.4142 113.267 26.5059C113.05 26.5976 112.908 26.7643 112.842 27.006C112.792 27.206 112.817 27.3894 112.917 27.5478C113.017 27.7061 113.225 27.9145 113.542 28.1646L114.35 28.7981C114.959 29.2815 115.376 29.7399 115.592 30.1817C115.809 30.6235 115.859 31.1069 115.717 31.6403C115.534 32.3572 115.126 32.874 114.484 33.174C113.842 33.4824 113.117 33.5241 112.3 33.3157C111.633 33.1407 111.066 32.8323 110.608 32.3822C110.149 31.9321 109.833 31.3903 109.666 30.7568H109.674Z" fill="url(#paint18_linear_628_828)"/>
                <path d="M116.426 34.3493L119.026 26.5225L120.627 27.056L118.026 34.8827L116.426 34.3493Z" fill="url(#paint19_linear_628_828)"/>
                <path d="M127.821 29.89L129.371 30.5484L126.07 38.2751L125.554 38.0584L123.161 31.4903L121.219 36.0413L119.669 35.3745L122.961 27.6478L123.47 27.8645L125.879 34.416L127.812 29.8816L127.821 29.89Z" fill="url(#paint20_linear_628_828)"/>
                <path d="M132.255 41.5342L132.388 40.3673L129.454 38.7002L128.521 39.4087L126.945 38.5169L134.305 33.0407L134.797 33.3157L133.839 42.4344L132.263 41.5425L132.255 41.5342ZM132.563 38.8753L132.988 35.908L130.654 37.7834L132.563 38.8753Z" fill="url(#paint21_linear_628_828)"/>
                <path d="M134.563 42.9428L139.423 36.2747L141.607 37.8667C142.682 38.6252 143.332 39.5671 143.565 40.6923C143.799 41.8176 143.549 42.8595 142.832 43.8264C142.14 44.8099 141.215 45.3601 140.048 45.5017C138.889 45.6351 137.789 45.31 136.747 44.5349L134.563 42.9428ZM136.822 42.7011L137.631 43.2929C138.306 43.7847 138.989 43.9847 139.681 43.8931C140.373 43.8014 140.957 43.443 141.423 42.8011C141.89 42.151 142.065 41.5009 141.932 40.834C141.798 40.1672 141.39 39.5838 140.698 39.0837L139.89 38.4919L136.831 42.6928L136.822 42.7011Z" fill="url(#paint22_linear_628_828)"/>
                <path d="M15.0038 86.4275L17.1543 91.2619L22.4304 91.8204L18.4879 95.3628L19.5881 100.547L15.0038 97.8967L10.4111 100.547L11.5197 95.3628L7.57715 91.8204L12.845 91.2619L15.0038 86.4275Z" fill="url(#paint23_linear_628_828)"/>
                <path d="M17.9541 69.9822L19.4544 73.3746L23.1469 73.758L20.388 76.2419L21.1631 79.8677L17.9541 78.009L14.7367 79.8677L15.5119 76.2419L12.7529 73.758L16.4454 73.3746L17.9541 69.9822Z" fill="url(#paint24_linear_628_828)"/>
                <path d="M18.8467 107.541L20.3553 110.933L24.0395 111.316L21.2889 113.8L22.0557 117.426L18.8467 115.567L15.6293 117.426L16.4045 113.8L13.6455 111.316L17.338 110.933L18.8467 107.541Z" fill="url(#paint25_linear_628_828)"/>
                <path d="M172.28 86.4275L174.438 91.2619L179.706 91.8204L175.764 95.3628L176.872 100.547L172.28 97.8967L167.695 100.547L168.796 95.3628L164.861 91.8204L170.129 91.2619L172.28 86.4275Z" fill="url(#paint26_linear_628_828)"/>
                <path d="M168.353 69.9822L169.862 73.3746L173.546 73.758L170.787 76.2419L171.563 79.8677L168.353 78.009L165.136 79.8677L165.911 76.2419L163.152 73.758L166.845 73.3746L168.353 69.9822Z" fill="url(#paint27_linear_628_828)"/>
                <path d="M169.246 107.541L170.754 110.933L174.447 111.316L171.688 113.8L172.455 117.426L169.246 115.567L166.036 117.426L166.803 113.8L164.053 111.316L167.737 110.933L169.246 107.541Z" fill="url(#paint28_linear_628_828)"/>
                <path d="M65.0395 152.001L64.1893 150.025H57.9962L57.146 152.001H53.8203L60.5801 136.589H61.6137L68.3569 152.001H65.0311H65.0395ZM63.114 147.483L61.0969 142.373L59.0798 147.483H63.114Z" fill="url(#paint29_linear_628_828)"/>
                <path d="M80.2016 152L79.3514 150.025H73.1583L72.3082 152H68.9824L75.7423 136.589H76.7758L83.519 152H80.1932H80.2016ZM78.2761 147.483L76.259 142.373L74.2419 147.483H78.2761Z" fill="url(#paint30_linear_628_828)"/>
                <path d="M84.4189 148.925L86.5527 147.316C87.6197 148.783 88.8616 149.516 90.2702 149.516C90.9454 149.516 91.4705 149.366 91.8539 149.066C92.2373 148.766 92.4291 148.358 92.4291 147.841C92.4291 147.416 92.2957 147.083 92.0373 146.841C91.7789 146.607 91.2621 146.307 90.5036 145.957L88.8783 145.224C86.4277 144.14 85.2108 142.69 85.2108 140.865C85.2108 139.581 85.6859 138.547 86.6278 137.756C87.578 136.972 88.7782 136.572 90.2286 136.572C91.3622 136.572 92.354 136.83 93.2126 137.339C94.0711 137.856 94.7379 138.547 95.2297 139.439L93.1209 141.065C92.3457 139.906 91.3788 139.323 90.2286 139.323C89.6784 139.323 89.2283 139.456 88.8783 139.714C88.5282 139.973 88.3532 140.331 88.3532 140.79C88.3532 141.173 88.4782 141.481 88.7282 141.715C88.9783 141.948 89.4451 142.223 90.1119 142.532L91.8289 143.29C93.1292 143.874 94.0794 144.499 94.6712 145.182C95.263 145.866 95.5631 146.708 95.5631 147.716C95.5631 149.075 95.063 150.175 94.0711 151.008C93.0792 151.842 91.8123 152.259 90.2702 152.259C89.0033 152.259 87.8614 151.959 86.8445 151.367C85.8276 150.767 85.0191 149.95 84.4273 148.916L84.4189 148.925Z" fill="url(#paint31_linear_628_828)"/>
                <path d="M101.705 152V139.664H96.8711V136.864H109.666V139.664H104.806V152H101.714H101.705Z" fill="url(#paint32_linear_628_828)"/>
                <path d="M119.751 152L118.901 150.025H112.708L111.858 152H108.532L115.292 136.589H116.326L123.069 152H119.743H119.751ZM117.826 147.483L115.809 142.373L113.792 147.483H117.826Z" fill="url(#paint33_linear_628_828)"/>
                <path d="M126.836 152V139.664H122.002V136.864H134.796V139.664H129.937V152H126.845H126.836Z" fill="url(#paint34_linear_628_828)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_628_828" x1="128.854" y1="4.9761" x2="64.2226" y2="170.054" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint1_linear_628_828" x1="0" y1="93.9958" x2="188" y2="93.9958" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint2_linear_628_828" x1="80.5177" y1="-13.9448" x2="15.8867" y2="151.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint3_linear_628_828" x1="176.272" y1="23.5471" x2="111.641" y2="188.617" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint4_linear_628_828" x1="144.632" y1="92.4955" x2="162.277" y2="92.4955" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint5_linear_628_828" x1="53.6363" y1="88.5696" x2="129.545" y2="88.5696" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint6_linear_628_828" x1="53.6281" y1="88.953" x2="129.545" y2="88.953" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint7_linear_628_828" x1="59.5214" y1="122.235" x2="72.791" y2="46.9687" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint8_linear_628_828" x1="99.8888" y1="129.345" x2="113.158" y2="54.0953" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint9_linear_628_828" x1="43.2767" y1="41.7509" x2="142.557" y2="33.7908" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint10_linear_628_828" x1="43.068" y1="39.1087" x2="142.348" y2="31.1486" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint11_linear_628_828" x1="42.7845" y1="35.5996" x2="142.065" y2="27.6395" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint12_linear_628_828" x1="42.585" y1="33.1573" x2="141.865" y2="25.1889" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint13_linear_628_828" x1="42.4436" y1="31.3403" x2="141.724" y2="23.3802" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint14_linear_628_828" x1="42.402" y1="30.7985" x2="141.682" y2="22.8301" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint15_linear_628_828" x1="42.3852" y1="30.6151" x2="141.665" y2="22.655" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint16_linear_628_828" x1="42.4181" y1="30.9985" x2="141.698" y2="23.0384" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint17_linear_628_828" x1="42.5766" y1="33.024" x2="141.857" y2="25.0555" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint18_linear_628_828" x1="42.7262" y1="34.8494" x2="142.007" y2="26.881" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint19_linear_628_828" x1="42.876" y1="36.7665" x2="142.157" y2="28.798" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint20_linear_628_828" x1="43.0937" y1="39.5004" x2="142.374" y2="31.532" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint21_linear_628_828" x1="43.5352" y1="44.9933" x2="142.815" y2="37.0249" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint22_linear_628_828" x1="43.8267" y1="48.5774" x2="143.107" y2="40.6173" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint23_linear_628_828" x1="37.2504" y1="30.5234" x2="-11.227" y2="165.028" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint24_linear_628_828" x1="34.141" y1="29.1315" x2="-14.3364" y2="163.636" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint25_linear_628_828" x1="46.9112" y1="33.7325" x2="-1.55788" y2="168.237" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint26_linear_628_828" x1="194.526" y1="30.5234" x2="146.049" y2="165.028" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint27_linear_628_828" x1="184.54" y1="29.1231" x2="136.071" y2="163.636" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint28_linear_628_828" x1="197.318" y1="33.7325" x2="148.841" y2="168.237" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint29_linear_628_828" x1="51.5281" y1="142.632" x2="128.629" y2="163.353" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint30_linear_628_828" x1="52.5455" y1="138.831" x2="129.654" y2="159.552" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint31_linear_628_828" x1="53.6788" y1="134.622" x2="130.779" y2="155.343" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint32_linear_628_828" x1="54.7201" y1="130.737" x2="131.829" y2="151.459" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint33_linear_628_828" x1="55.2121" y1="128.92" x2="132.312" y2="149.641" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <linearGradient id="paint34_linear_628_828" x1="56.4124" y1="124.444" x2="133.521" y2="145.157" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C59533"/>
                    <stop offset="0.01" stopColor="#D2AE4B"/>
                    <stop offset="0.04" stopColor="#E2CB68"/>
                    <stop offset="0.06" stopColor="#EFE27E"/>
                    <stop offset="0.08" stopColor="#F8F28D"/>
                    <stop offset="0.11" stopColor="#FDFC97"/>
                    <stop offset="0.15" stopColor="#FFFF9A"/>
                    <stop offset="0.29" stopColor="#FBDE68"/>
                    <stop offset="0.48" stopColor="#AE8323"/>
                    <stop offset="0.62" stopColor="#83561D"/>
                    <stop offset="0.76" stopColor="#AE8323"/>
                    <stop offset="0.9" stopColor="#EAC954"/>
                    <stop offset="0.96" stopColor="#F6E778"/>
                    <stop offset="1" stopColor="#FFFB90"/>
                </linearGradient>
                <clipPath id="clip0_628_828">
                    <rect width="188" height="188" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}