import React, { useState } from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {prefixPath} from "../utils/helpers";
import EnFlag from "../icons/EnFlag";
import EtFlag from "../icons/EtFlag";
import FiFlag from "../icons/FiFlag";
import ArrowTop from "../icons/ArrowTop";
import {toUpper} from "lodash";

const LanguageSwitcher = ({ t, location, onHeaderClose}) => {
  const { i18n } = useTranslation();
  const [isToggled, setIsToggled] = useState(false);

  const handleLanguageChange = (locale) => {
    i18n.changeLanguage(locale);
    window.document.documentElement.lang = locale;

    const currentPath = location.pathname;
    const split = currentPath.split('/');

    const lastPart = split.slice(-1)[0];
    const onMainPage = ['en', 'fi', ''].includes(lastPart);

    let route = '';
    if (split.length && split.length >= 2) {
      split.forEach(routeItem => {
        if (!['en', 'fi', ''].includes(routeItem)) {
          route += routeItem + '/'
        }
      });
    }

    let currentLocalizedRoute = '';
    currentLocalizedRoute = prefixPath(
        onMainPage ? '/' : route !== '' ? route : lastPart,
        locale
    );

    history.pushState(null, null, currentLocalizedRoute);
    document.title = onMainPage ? "Elektrimasind" : t(lastPart);
    setIsToggled(!isToggled);
    setTimeout(() => {
      onHeaderClose(!isToggled);
    }, 300)
  };

  const localesToSwitch = ['et', 'en', 'fi'].filter(x => ![i18n.language].includes(x));
  const getIconByLocale = (locale) => {
    switch(locale) {
      case 'en':
        return <EnFlag/>;
      case 'fi':
        return <FiFlag/>;
      default:
        return <EtFlag/>;
    }
  }

  return (
      <div className={`switcher ${isToggled && '_show'}`}>
        <div className="switcher__current">
          <div className="switcher__mobile-text">{t('choose_lang')}</div>
          <button className="switcher__button" onClick={() => setIsToggled(!isToggled)}>
            {getIconByLocale(i18n.language)}
            {toUpper(i18n.language)}
            <ArrowTop/>
          </button>
        </div>
        <div className={`switcher__container`}>
          {
            localesToSwitch.map((locale, key) => {
              return (
                  <button key={key} className="switcher__button switcher__button_new" onClick={() => handleLanguageChange(locale)}>
                    {getIconByLocale(locale)}
                    {toUpper(locale)}
                  </button>
              )
            })
          }
        </div>
      </div>
  );
};

export default withTranslation()(LanguageSwitcher);