import { combineReducers } from 'redux';
import settingsReducer from './settingsReducer';
import offersReducer from "./offersReducer";
import serviceReducer from "./serviceReducer";
import breadcrumbsReducer from "./breadcrumbsReducer";

export default combineReducers({
    settings: settingsReducer,
    offers: offersReducer,
    services: serviceReducer,
    breadcrumbs: breadcrumbsReducer,
});