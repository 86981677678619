import React from 'react'
import Layout from "../components/Layout"
import Header from '../components/Header'
import Footer from "../components/footer";

function NotFound() {
    return (
        <Layout>
            <Header/>
            <div className="container">
                <h1 className="text-center mt-5 mb-3">404 | Page Not Found</h1>
            </div>
            <Footer/>
        </Layout>
    );
}

export default NotFound;