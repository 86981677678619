import React from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {prefixPath} from "../utils/helpers";
import ArrowRightHeader from "../icons/ArrowRightHeader";
import {useSelector} from "react-redux";
import * as storageSelectors from "../selectors/selectors";
import ArrowLeftLong from "../icons/ArrowLeftLong";

const Breadcrumbs = ({ t }) => {
    const { i18n } = useTranslation();
    const locale = i18n.language;
    const link = useSelector(storageSelectors.breadcrumbs);
    // console.log(link);
    const serviceTextByLang = (link) => {
        return i18n.language === 'et'
            ? link.nameEt
            : i18n.language === 'en'
                ? link.nameEn : link.nameFi;
    }

    const setDocumentTitleDefault = () => {
        document.title = "Elektrimasinad";
    }

    let serviceParentLink = null;

    // if (typeof link.service === 'object' && link.service.parent && link.service.parent.slug !== 'repair-and-maintenance-of-electric-motors') {
    //     serviceParent = link.service.parent;
    // }
    if (typeof link.service === 'object' && link.service.parent) {
        serviceParentLink = prefixPath(`/services/${link.service.parent.slug}`, locale);
    }

    return (
        <div className="breadcrumbs">
            {
                link.service !== 'undefined' && link.service
                ?
                    <Link to={serviceParentLink !== null ? serviceParentLink : prefixPath("/", locale)} className={"breadcrumbs__back-link"}>
                        <span className="breadcrumbs__back-link-arrow"><ArrowLeftLong/></span>
                        <span className="breadcrumbs__back-link-text">{t('back-link-text')}</span>
                    </Link>
                    // <ul className="breadcrumbs__list">
                    //     <li className="breadcrumbs__item">
                    //         <Link to={prefixPath("/", locale)}
                    //               className="breadcrumbs__link"
                    //               title="Elektrimasinad"
                    //               onClick={setDocumentTitleDefault}>
                    //             {t('main_page')}
                    //         </Link>
                    //     </li>
                    //     <li className="breadcrumbs__delimiter"><ArrowRightHeader/></li>
                    //     {/*<li className="breadcrumbs__item">
                    //         <Link to={prefixPath("/", locale)}
                    //               className="breadcrumbs__link"
                    //               title="Elektrimasinad"
                    //               onClick={setDocumentTitleDefault}>
                    //             {t('services')}
                    //         </Link>
                    //     </li>*/}
                    //     {
                    //         typeof link.service === 'object' && serviceParent
                    //             ?
                    //             <li className="breadcrumbs__item">
                    //                 <Link to={ prefixPath(`/services/${serviceParent.slug}`, locale)}
                    //                       className="breadcrumbs__link"
                    //                       title={serviceTextByLang(serviceParent)}>
                    //                     {serviceTextByLang(serviceParent)}
                    //                 </Link>
                    //             </li>
                    //             : ''
                    //     }
                    //     { typeof link.service === 'object' && serviceParent
                    //         ? <li className="breadcrumbs__delimiter"><ArrowRightHeader/></li>
                    //         : ''
                    //     }
                    //     <li className="breadcrumbs__item">
                    //         <div className="breadcrumbs__link _active">
                    //             {serviceTextByLang(link.service)}
                    //         </div>
                    //     </li>
                    // </ul>
                : link.pageName !== 'undefined' && link.pageName
                ?
                        <Link to={serviceParentLink !== null ? serviceParentLink : prefixPath("/", locale)} className={"breadcrumbs__back-link"}>
                            <span className="breadcrumbs__back-link-arrow"><ArrowLeftLong/></span>
                            <span className="breadcrumbs__back-link-text">{t('back-link-text')}</span>
                        </Link>
                        // <ul className="breadcrumbs__list">
                        //     <li className="breadcrumbs__item">
                        //         <Link to={prefixPath("/", locale)}
                        //               className="breadcrumbs__link"
                        //               title="Elektrimasinad"
                        //               onClick={setDocumentTitleDefault}>
                        //             {t('main_page')}
                        //         </Link>
                        //     </li>
                        //     <li className="breadcrumbs__delimiter"><ArrowRightHeader/></li>
                        //     <li className="breadcrumbs__item">
                        //         <div className="breadcrumbs__link _active">
                        //             {link.pageName}
                        //         </div>
                        //     </li>
                        // </ul>
                : ''
            }
        </div>
    );
};

export default withTranslation()(Breadcrumbs);