import React from "react";
import { useSelector } from "react-redux";
import * as storageSelectors from "../selectors/selectors";

const Settings = ({className = ''}) => {
    const settings = useSelector(storageSelectors.settings);

    return (
        <ul className={`settings ${className}`}>
            {settings.map((setting, key) => {
                return (
                    <li key={key} className="settings__info-item">
                        {setting}
                    </li>
                );
            })}
        </ul>
    );
};

export default Settings;