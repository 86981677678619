export function smartTab(e) {
  let button = this;
  if (button.tagName === 'A') {
    e.preventDefault();
  }

  let selector = button.dataset.tab;
  if (!selector && button.getAttribute('href')) {
    selector = button.getAttribute('href');
  }

  let tab = document.querySelector(selector);
  if (tab) {
    tab.parentNode.querySelectorAll(".smart-content").forEach((item) => { item.classList.remove('_active') });
    tab.classList.add('_active');
  }
  button.closest('.smart-tabs').querySelectorAll('li').forEach((item) => {
    if (!item.classList.contains('smart-content')) {
      item.classList.remove('_active')
    }
  });
  button.closest('li').classList.add('_active');

  let event = new CustomEvent('smart-tabs-handle', {'detail': {'button': button, 'tab': tab}});
  document.dispatchEvent(event);
  if (window.innerWidth > 1199) {
    window.dispatchEvent(new Event('resize'));
  }
}