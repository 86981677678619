import React, {useEffect, useState} from 'react'
import {useParams, Navigate} from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from "../components/Layout"
import Header from '../components/Header'
import {useTranslation, withTranslation} from "react-i18next";
import {makeRequest} from "../api/fetcher";
import Footer from "../components/footer";
import store from "../store";
import {setBreadcrumbs} from "../actions";
import Breadcrumbs from "../components/breadcrumbs";
import GrundfosIcon from "../icons/GrundfosIcon";
import {getBackgroundImage} from "../icons/serviceBgByService";
import PdfIcon from "../icons/PdfIcon";

const ServiceCategory = ({ t }) => {
    const { i18n } = useTranslation();
    const { serviceCategorySlug, serviceSlug } = useParams();
    const [category, setCategory] = useState([]);
    const [service, setService] = useState([]);
    const [response, setResponse] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await makeRequest({
                method: 'get',
                url: `/api/service/${serviceCategorySlug}/${serviceSlug}`
            });

            const response = data.data;
            setResponse(response);
            if (response.parent !== 'undefined' && response.child !== 'undefined') {
                setCategory(response.parent);
                setService(response.child);
            }

            store.dispatch(setBreadcrumbs({'service': response.child}));
        };

        fetchData();
    }, [serviceCategorySlug, serviceSlug])

    const getServiceName = (service) => {
        return i18n.language === 'et'
            ? service.nameEt
            : i18n.language === 'en'
                ? service.nameEn : service.nameFi;
    }

    const isListHasOneColumn = (service) => {
        return [
            'diagnostics-of-industrial-equipment',
            'activities-and-measurable-parameters',
            'equipment-and-systems-used',
            'diagnostic-moto-and-catchphrases'
        ].includes(service.slug);
    }

    if (response.status === 'error') {
        return <Navigate to="/"/>
    }

    return (
        <Layout>
            <Header/>
            <div className="layout__inner layout__inner_gallery">
                <Breadcrumbs/>
                <h1 className="layout__h1">
                    {getServiceName(service)}
                </h1>
                <div className="service-category">
                    {
                        service.descriptionKey
                            ?
                            <div className="service-category__description" dangerouslySetInnerHTML={{__html: t(`${service.descriptionKey}`) }}>
                            </div>
                            : ''
                    }

                    {
                        service.titleKey
                            ?
                            <div className={`service-category__title ${service.slug === 'electric-motor-repair-includes' || service.slug === 'sale-of-new-electric-motors' ? '_without-space' : ''}`} dangerouslySetInnerHTML={{__html: t(`${service.titleKey}`) }}>
                            </div>
                            : ''
                    }

                    <div className={`service-category__lists-container 
                    ${service.secondListKey === undefined || service.secondListKey === '' ? '_full': ''} 
                    ${service.slug === 'electric-motor-repair-includes' ? '_full' : ''}
                    ${service.slug === 'equipment-and-systems-used' ? '_fit-content' : ''}
                    `}>
                        {
                            service.firstListKey
                                ?
                                <div className={`service-category__list-outer`} dangerouslySetInnerHTML={{__html: t(`${service.firstListKey}`) }}>
                                </div>

                                : ''
                        }

                        {
                            service.secondListKey
                                ?
                                <div className="service-category__list-outer" dangerouslySetInnerHTML={{__html: t(`${service.secondListKey}`) }}>
                                </div>
                                : ''
                        }

                        {
                            service.slug === 'pumps'
                                ?
                                <div className="service-category__pumbad-partner">
                                    <a href="https://www.grundfos.com" title="Grundfos" target="_blank" rel="nofollow" className="service-category__pumbad-partner-icon"><GrundfosIcon/></a>
                                    <div className="service-category__pumbad-partner-text">{t('pumbad_partner_info_key')}</div>
                                </div>
                                : ''
                        }
                    </div>

                    {
                        getBackgroundImage(service) !== ''
                        ?
                            <div className={`service-category__background-image-wrapper ${service.slug}`}>
                                <img src={`${getBackgroundImage(service)}`} className="service-category__background-image"/>
                            </div>
                        : ''
                    }

                    {
                        service.slug === 'equipment-and-systems-used'
                        ?
                            <ul className="service-category__doc-list">
                                <li className="service-category__doc-item">
                                    <a href="/documents/skf-quick-collektor.pdf" target={"_blank"} title={t('skf_quick_collector')} className="service-category__doc-link">
                                        <span className="service-category__doc-text">
                                            {t('skf_quick_collector')}
                                        </span>
                                        <span className="service-category__doc-item">
                                            <PdfIcon/>
                                        </span>
                                    </a>
                                </li>
                                <li className="service-category__doc-item">
                                    <a href="/documents/imx-8.pdf" title={t('skf_online_mx16plus')} className="service-category__doc-link">
                                        <span className="service-category__doc-text">
                                            {t('skf_online_mx16plus')}
                                        </span>
                                        <span className="service-category__doc-item">
                                            <PdfIcon/>
                                        </span>
                                    </a>
                                </li>
                                <li className="service-category__doc-item">
                                    <a href="/documents/skf-on-line-imx-1.pdf" title={t('skf_online_mx1')} className="service-category__doc-link">
                                        <span className="service-category__doc-text">
                                            {t('skf_online_mx1')}
                                        </span>
                                        <span className="service-category__doc-item">
                                            <PdfIcon/>
                                        </span>
                                    </a>
                                </li>
                                <li className="service-category__doc-item">
                                    <a href="/documents/hes.pdf" title={t('loodad_programmid')} className="service-category__doc-link">
                                        <span className="service-category__doc-text">
                                            {t('loodad_programmid')}
                                        </span>
                                        <span className="service-category__doc-item">
                                            <PdfIcon/>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        : ''
                    }
                </div>
            </div>
            <Footer/>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};

const mapDispatchToProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ServiceCategory));