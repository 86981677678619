import * as React from "react";

export default function EnFlag() {
    return (
        <svg className={"flag-icon"} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <mask id="a" width="32" height="24" x="-4" y="0" maskUnits="userSpaceOnUse" style={{maskType: 'luminance'}}>
                <path fill="#fff" d="M-4 0h32v24H-4z"/>
            </mask>
            <g mask="url(#a)">
            <path fill="#2E42A5" fillRule="evenodd" d="M-4 0v24h32V0H-4Z" clipRule="evenodd"/>
                <mask id="b" width="32" height="24" x="-4" y="0" maskUnits="userSpaceOnUse" style={{maskType: 'luminance'}}>
                    <path fill="#fff" fillRule="evenodd" d="M-4 0v24h32V0H-4Z" clipRule="evenodd"/>
                </mask>
                <g mask="url(#b)">
                    <mask id="c" width="32" height="24" x="-4" y="0" maskUnits="userSpaceOnUse" style={{maskType: 'luminance'}}>
                        <path fill="#fff" d="M-4 0h32v24H-4z"/>
                    </mask>
                    <g mask="url(#c)">
                        <path fill="#fff" d="m-7.563 22.285 7.042 2.979 28.68-22.026 3.715-4.426-7.53-.995-11.698 9.491-9.416 6.396-10.793 8.581Z"/>
                        <path fill="#F50100" d="m-6.6 24.372 3.588 1.728L30.54-1.599h-5.037l-32.102 25.97Z"/>
                        <path fill="#fff" d="m31.563 22.285-7.042 2.979-28.68-22.026-3.715-4.426 7.53-.995 11.698 9.491 9.416 6.396 10.793 8.581Z"/>
                        <path fill="#F50100" d="m31.323 23.783-3.588 1.728-14.287-11.86-4.235-1.324-17.445-13.5h5.038l17.434 13.18 4.631 1.588 12.452 10.188Z"/>
                        <mask id="d" fill="#fff">
                            <path fillRule="evenodd" d="M15.778-2H8.223V8H-5.972v8H8.223v10h7.555V16h14.25V8h-14.25V-2Z" clipRule="evenodd"/>
                        </mask>
                        <path fill="#F50100" fillRule="evenodd" d="M15.778-2H8.223V8H-5.972v8H8.223v10h7.555V16h14.25V8h-14.25V-2Z" clipRule="evenodd"/>
                        <path fill="#fff" d="M8.223-2v-2h-2v2h2Zm7.555 0h2v-2h-2v2ZM8.223 8v2h2V8h-2ZM-5.972 8V6h-2v2h2Zm0 8h-2v2h2v-2Zm14.195 0h2v-2h-2v2Zm0 10h-2v2h2v-2Zm7.555 0v2h2v-2h-2Zm0-10v-2h-2v2h2Zm14.25 0v2h2v-2h-2Zm0-8h2V6h-2v2Zm-14.25 0h-2v2h2V8ZM8.223 0h7.555v-4H8.223v4Zm2 8V-2h-4V8h4Zm-16.195 2H8.223V6H-5.972v4Zm2 6V8h-4v8h4Zm12.195-2H-5.972v4H8.223v-4Zm2 12V16h-4v10h4Zm5.555-2H8.223v4h7.555v-4Zm-2-8v10h4V16h-4Zm16.25-2h-14.25v4h14.25v-4Zm-2-6v8h4V8h-4Zm-12.25 2h14.25V6h-14.25v4Zm-2-12V8h4V-2h-4Z" mask="url(#d)"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}