import {makeRequest} from "../api/fetcher";
import * as types from "../types";

export const getSettings = () => async (dispatch) => {
    const res = await makeRequest({
        method: 'get',
        url: `/api/settings`
    });

    dispatch({
        type: types.GET_SETTINGS,
        payload: res.data ? res.data : []
    });
}

export const getOffers = () => async (dispatch) => {
    const res = await makeRequest({
        method: 'get',
        url: `/api/offers`
    });

    dispatch({
        type: types.GET_OFFERS,
        payload: res.data ? res.data : []
    });
}

export const getServices = () => async (dispatch) => {
    const res = await makeRequest({
        method: 'get',
        url: `/api/services`
    });

    dispatch({
        type: types.GET_SERVICES,
        payload: res.data ? res.data : []
    });
}

export const setBreadcrumbs = (links) => async (dispatch) => {
    dispatch({
        type: types.SET_BREADCRUMBS,
        payload: links
    });
}