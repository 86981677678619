import React, {useEffect, useState} from 'react';
import { Link, useLocation } from "react-router-dom";
import Logo from "../icons/Logo";
import LanguageSwitcher from '../components/LanguageSwitcher'
import {connect, useSelector} from 'react-redux';
import {useTranslation, withTranslation} from 'react-i18next';
import {prefixPath} from "../utils/helpers";
import { Spin as Hamburger } from 'hamburger-react'
import ArrowRightHeader from "../icons/ArrowRightHeader";
import PartnerLogos from "./partner-logos";
import Settings from "./settings";
import * as storageSelectors from "../selectors/selectors";
import SkfIcon from "../icons/SkfIcon";

const Header = ({ t }) => {
    const services = useSelector(storageSelectors.services);
    const location = useLocation();
    const { i18n } = useTranslation();
    const locale = i18n.language;
    const pageLinks = [
        {
            "name": t('services'),
            "url" : prefixPath("/services", locale),
            "location": {
                "pathname": prefixPath("/services", locale)
            },
            "hasChild": true
        },
        {
            "name": t('gallery'),
            "url" : prefixPath("/gallery", locale),
            "location": {
                "pathname": prefixPath("/gallery", locale)
            }
        },
        {
            "name": t('about'),
            "url" : prefixPath("/about", locale),
            "location": {
                "pathname": prefixPath("/about", locale)
            }
        },
        {
            "name": t('references'),
            "url" : prefixPath("/references", locale),
            "location": {
                "pathname": prefixPath("/references", locale)
            }
        },
        /*{
            "name": t('feedback'),
            "url" : prefixPath("/feedback", locale),
            "location": {
                "pathname": prefixPath("/feedback", locale)
            }
        },*/
        {
            "name": t('contacts'),
            "url" : prefixPath("/contacts", locale),
            "location": {
                "pathname": prefixPath("/contacts", locale)
            }
        },
    ];

    const serviceTextByLang = (service) => {
        return i18n.language === 'et'
            ? service.nameEt
            : i18n.language === 'en'
                ? service.nameEn : service.nameFi;
    }

    const getChildTextByLang = (child) => {
        return i18n.language === 'et'
            ? child.nameEt
            : i18n.language === 'en'
                ? child.nameEn : child.nameFi;
    }

    const isServiceHasChilds = (service) => {
        return Object.keys(service.childServices).length;
    }

    const setDocumentTitleDefault = () => {
        document.title = "Elektrimasinad";
    }

    const currentPath = location.pathname;
    const split = currentPath.split('/');
    const lastPart = split.slice(-1)[0];
    const onMainPage = ['en', 'fi', ''].includes(lastPart);
    const url = onMainPage ? "Elektrimasind" : t(lastPart);

    const [isHovered, setIsHovered] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [isServicesVisible, setServicesVisible] = useState(false)
    const [isMobileChildServicesVisible, setMobileChildServicesVisible] = useState(false)
    let tempScrollTop, currentScrollTop = 0;

    useEffect(() => {
        pageLinks.map((page) => {
            if (page.url === location.pathname) {
                document.title = page.name;
            }
        });

        window.onscroll = () => {
            currentScrollTop = window.pageYOffset
            if (tempScrollTop < currentScrollTop) {
                if (window.pageYOffset > 73 && document.getElementById('header').classList.contains('_visible')) {
                    document.getElementById('header').classList.remove('_visible')
                }
            } else if (tempScrollTop > currentScrollTop) {
                if (!document.getElementById('header').classList.contains('_visible')) {
                    document.getElementById('header').classList.add('_visible')
                }
            }

            tempScrollTop = currentScrollTop;
        }
    }, [])

    return (
        <header id={'header'} className={`header _visible ${isOpen && '_show'} ${isServicesVisible && '_services-visible'} ${isMobileChildServicesVisible && '_mobile-child-services-visible'}`}>
            <Link to={prefixPath("/", locale)} className="header__logo" title="Elektrimasinad" onClick={setDocumentTitleDefault}>
                <Logo />
            </Link>

            <div className="header__burger-container">
                <Hamburger direction="right" size={24} color={"#FFFFFF"} label="Show menu" toggled={isOpen} toggle={setOpen} onToggle={toggled => {
                    if (toggled) {
                        document.body.style.overflow = 'hidden';
                    } else {
                        document.body.style.overflow = '';
                    }
                }}/>
            </div>

            <div className="header__switcher header__switcher_desktop">
                <LanguageSwitcher location={location} onHeaderClose={setOpen}/>
            </div>
            <PartnerLogos className={'partner-logo-container_header-desktop'}/>

            <div className={`header__inner ${isOpen && '_show'}`}>
                <ul className="header__nav-list">
                    {
                        pageLinks.map((page, key) => {
                            return (
                                <li key={key} className={`header__nav-item ${url === page.name ? '_active' : ''} ${page.hasChild ? '_services' : ''}`}>
                                    <Link to={ page.hasChild ? void(0) : page.url} title={page.name}
                                          onClick={page.hasChild ? () => {
                                              setIsHovered(false);
                                              setServicesVisible(!isServicesVisible);
                                          } : {}}
                                          className="header__nav-link">
                                        {page.name}
                                        {page.hasChild ? <span className="header__arrow-icon"><ArrowRightHeader/></span> : ''}
                                    </Link>

                                    {
                                        page.hasChild
                                        ?
                                            <span className={`header__mobile-services`}>
                                                <ul className="header__mobile-services-inner">
                                                    {services.map((service, key) => {
                                                        return (
                                                            <li key={key} className={"header__mobile-services-item"}>
                                                                <Link to={ isServiceHasChilds(service) ? void(0) : prefixPath(`/services/${service.slug}`, locale)}
                                                                      onClick={
                                                                        isServiceHasChilds(service)
                                                                            ? () => {
                                                                                setMobileChildServicesVisible(!isMobileChildServicesVisible)
                                                                            }
                                                                            : () => {
                                                                                setServicesVisible(!isServicesVisible)
                                                                                setOpen(!isOpen);
                                                                            }
                                                                    }
                                                                      className={`header__mobile-services-link ${isServiceHasChilds(service) ? '_has-childs' : ''}`}>
                                                                    {serviceTextByLang(service)}
                                                                    {isServiceHasChilds(service) ? <span className="header__child-arrow-icon"><ArrowRightHeader/></span> : ''}
                                                                </Link>
                                                                {
                                                                    isServiceHasChilds(service)
                                                                        ?
                                                                        <span key={key} className={`header__mobile-child-service`}>
                                                                            <span className="header__mobile-child-service-outer">
                                                                                {
                                                                                    Object.keys(service.childServices).map((id, key) => (
                                                                                        <span key={key} className={`header__mobile-child-service-inner`}>
                                                                                            <span className="header__mobile-child-service-title">
                                                                                                {t(id)}
                                                                                            </span>
                                                                                            <ul className="header__mobile-child-service-list">
                                                                                                {
                                                                                                    Object.keys(service.childServices[id]).map((child, key) => (
                                                                                                        <li key={key} className="header__mobile-child-service-item">
                                                                                                            <Link to={prefixPath(`/services/${service.slug}/${service.childServices[id][child].slug}`, locale)}
                                                                                                                  onClick={() => setServicesVisible(!isServicesVisible)} title={serviceTextByLang(service)}
                                                                                                                  className="header__mobile-child-service-link">
                                                                                                                {getChildTextByLang(service.childServices[id][child])}
                                                                                                            </Link>
                                                                                                        </li>
                                                                                                    ))
                                                                                                }
                                                                                            </ul>
                                                                                </span>
                                                                                    ))
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                        : ''
                                                                }
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </span>
                                        : ''
                                    }
                                </li>
                            )
                        })
                    }
                </ul>

                <div className="header__bottom">
                    <div className="header__switcher header__switcher_mobile">
                        <LanguageSwitcher location={location} onHeaderClose={setOpen}/>
                    </div>
                    <Settings className={'settings_header'}/>
                </div>
            </div>

            <div className="header__services-header-container">
                <div className="header__services-header-container-inner">
                    <div className="header__services-header-container-bg"></div>
                    <ul className="header__service-list">
                        {services.map((service, key) => {
                            return (
                                <li key={key} className="header__service-item">
                                    <Link to={ isServiceHasChilds(service) ? void(0) : prefixPath(`/services/${service.slug}`, locale)}
                                          onClick={isServiceHasChilds(service) ? () => {} : () => setServicesVisible(!isServicesVisible) }
                                          className="header__service-item-link" title={serviceTextByLang(service)}
                                          onMouseEnter={isServiceHasChilds(service) ? () => setIsHovered(true) : () => setIsHovered(false)}>
                                        {serviceTextByLang(service)}
                                        {isServiceHasChilds(service) ? <span className="header__arrow-icon"><ArrowRightHeader/></span> : ''}
                                    </Link>

                                    {
                                        isServiceHasChilds(service)
                                        ?
                                            <span key={key} className={`header__child-services ${isHovered && '_show'}`}>
                                                {
                                                    Object.keys(service.childServices).map((id, key) => (
                                                        <span key={key} className={`header__child-services-inner`}>
                                                            <span className="header__child-services-title">
                                                                {t(id)}
                                                            </span>
                                                            <ul className="header__child-services-list">
                                                                {
                                                                    Object.keys(service.childServices[id]).map((child, key) => (
                                                                        <li key={key} className="header__child-services-item">
                                                                            <Link to={prefixPath(`/services/${service.slug}/${service.childServices[id][child].slug}`, locale)}
                                                                                  onClick={() => {
                                                                                      setServicesVisible(!isServicesVisible)
                                                                                      setIsHovered(false)
                                                                                  }} title={serviceTextByLang(service)}
                                                                                  className="header__child-services-link">
                                                                                {getChildTextByLang(service.childServices[id][child])}
                                                                            </Link>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </span>
                                                    ))
                                                }
                                            </span>
                                        : ''
                                    }
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </header>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};

const mapDispatchToProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Header));