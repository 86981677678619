import * as React from "react";

export default function FiFlag() {
    return (
        <svg className={"flag-icon"} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <mask id="a" width="32" height="24" x="-4" y="0" maskUnits="userSpaceOnUse" style={{maskType: 'luminance'}}>
                <path fill="#fff" d="M-4 0h32v24H-4z"/>
            </mask>
            <g mask="url(#a)">
            <path fill="#F7FCFF" fillRule="evenodd" d="M-4 0v24h32V0H-4Z" clipRule="evenodd"/>
                <mask id="b" width="32" height="24" x="-4" y="0" maskUnits="userSpaceOnUse" style={{maskType: 'luminance'}}>
                    <path fill="#fff" fillRule="evenodd" d="M-4 0v24h32V0H-4Z" clipRule="evenodd"/>
                </mask>
                <g mask="url(#b)">
                    <path fill="#2E42A5" stroke="#2E42A5" strokeWidth="2" d="M6.819 1h-1v9.323H-5v4H5.819V25h4V14.323H29v-4H9.819V1h-3Z"/>
                </g>
            </g>
        </svg>
    )
}