import * as React from "react";

export function getBackgroundImage(service) {
    switch(service.slug) {
        case 'pumps':
            return (
                '/static/images/base/service/pumbad.png'
            );
        case 'cold-compressors':
            return (
                '/static/images/base/service/kulmakompressorid.png'
            );
        case 'electric-motor-repair-includes':
            return (
                '/static/images/base/service/elektromotori.png'
            );
        case 'gear-motors':
            return (
                '/static/images/base/service/reduklormootorid.png'
            );
        case 'fans':
            return (
                '/static/images/base/service/ventilaatorid.png'
            );
        case 'generators':
            return (
                '/static/images/base/service/generatori.png'
            );
        default:
            return ('');
    }
}