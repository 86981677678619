import React from 'react';
import {withTranslation} from "react-i18next";
import ArrowRight from "../icons/ArrowRight";
import NbIcon from "../icons/NbIcon";

const RemontHooldus = ({ t }) => {
    return (
        <div className={'repair'}>
            <div className="repair__caption">
                {t('repair_caption')}
            </div>
            <div className="repair__description">
                {t('repair_description')}
            </div>
            <a href={"/services/repair-and-maintenance-of-electric-motors/electric-motor-repair-includes"}
               title={t('elektromotori')} className="repair__item repair__item_nb">
                {t('elektromotori')}
                <span className="repair__nb-icon">
                    <NbIcon/>
                </span>
            </a>
            <div className="repair__title">
                {t('repair_first_title')}
            </div>
            <ul className="repair__list">
                <a href={"/services/repair-and-maintenance-of-electric-motors/pumps"}
                   title={t('pumbad')} className="repair__item">
                    {t('pumbad')}
                </a>
                <a href="/services/repair-and-maintenance-of-electric-motors/fans"
                    title={t('ventilaatorid')} className="repair__item">
                    {t('ventilaatorid')}
                </a>
                <a href="/services/repair-and-maintenance-of-electric-motors/cold-compressors"
                    title={t('kulmakompressorid')} className="repair__item">
                    {t('kulmakompressorid')}
                </a>
                <a href="/services/repair-and-maintenance-of-electric-motors/drum-motors"
                    title={t('trummelmootorid')} className="repair__item">
                    {t('trummelmootorid')}
                </a>
                <a href="/services/repair-and-maintenance-of-electric-motors/vibrators"
                    title={t('vibraatorid')} className="repair__item">
                    {t('vibraatorid')}
                </a>
                <a href="/services/repair-and-maintenance-of-electric-motors/transformers-coils-and-brake-coils"
                   title={t('tarfod')} className="repair__item">
                    {t('tarfod')}
                </a>
                <a href="/services/repair-and-maintenance-of-electric-motors/gear-motors"
                    title={t('reduktormootorid')} className="repair__item">
                    {t('reduktormootorid')}
                </a>
                <a href="/services/repair-and-maintenance-of-electric-motors/generators"
                    title={t('generaatorid')} className="repair__item">
                    {t('generaatorid')}
                </a>
            </ul>
            <div className="repair__title">
                {t('elektrimasinate')}
            </div>
            <a className="repair__item repair__item_arrow">
                {t('vaate')}
                <span className="service-category__icon-wrapper">
                    <ArrowRight/>
                </span>
            </a>
        </div>
    );
}

export default withTranslation()(RemontHooldus);
