import * as React from "react";

export default function EtFlag() {
    return (
        <svg className={"flag-icon"} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <mask id="a" width="32" height="24" x="-4" y="0" maskUnits="userSpaceOnUse" style={{maskType: 'luminance'}}>
                <path fill="#fff" d="M-4 0h32v24H-4z"/>
            </mask>
            <g mask="url(#a)">
                <path fill="#F7FCFF" fillRule="evenodd" d="M-4 0v24h32V0H-4Z" clipRule="evenodd"/>
                <mask id="b" width="32" height="24" x="-4" y="0" maskUnits="userSpaceOnUse" style={{maskType: 'luminance'}}>
                    <path fill="#fff" fillRule="evenodd" d="M-4 0v24h32V0H-4Z" clipRule="evenodd"/>
                </mask>
                <g fillRule="evenodd" clipRule="evenodd" mask="url(#b)">
                    <path fill="#40A8FF" d="M-4 0v8h32V0H-4Z"/>
                    <path fill="#272727" d="M-4 8v8h32V8H-4Z"/>
                </g>
            </g>
        </svg>
    );
}