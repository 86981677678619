import React, {useEffect, useState} from 'react'
import Layout from "../components/Layout"
import Header from '../components/Header'
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Footer from "../components/footer";
import store from "../store";
import {setBreadcrumbs} from "../actions";
import Breadcrumbs from "../components/breadcrumbs";
import {makeRequest} from "../api/fetcher";
import {GetPreferencesIconByKey} from "../icons/getPreferencesIcon";

const References = ({ t }) => {
    const [references, setReferences] = useState([]);

    useEffect(() => {
        const fetchReferences = async () => {
            const references = await makeRequest({
                method: 'get',
                url: `/api/references`
            });

            setReferences(references.data ? Object.values(references.data) : []);
            store.dispatch(setBreadcrumbs({'pageName': t('references')}));
        };

        fetchReferences();
    }, [])

    return (
        <Layout>
            <Header/>
            <div className="layout__inner layout__inner_gallery">
                <Breadcrumbs/>
                <h1 className="layout__h1">
                    {t('references')}
                </h1>
                <div className="references-page">
                    <div className="references-page__description">
                        {t('references_description')}
                    </div>
                    <ul className="references-page__list">
                        {
                            references.map((reference, key) => {
                                return (
                                    <li className="references-page__item" key={key}>
                                        <span className="references-page__image">
                                            <GetPreferencesIconByKey preferenceKey={key}/>
                                        </span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <Footer/>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};

const mapDispatchToProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(References));