import React, {useEffect, useState, useRef} from 'react'
import Layout from "../components/Layout"
import Header from '../components/Header'
import {connect, useSelector} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
SwiperCore.use([Pagination, Autoplay, Navigation]);
import Draggon from "../../../components/draggon/draggon";
import ArrowLeft from "../icons/ArrowLeft";
import ArrowRight from "../icons/ArrowRight";
import {Link} from "react-router-dom";
import {prefixPath} from "../utils/helpers";
import Footer from "../components/footer";
import * as storageSelectors from "../selectors/selectors";
import {GetServiceIconByKey} from "../icons/serviceIconByKey";
import PartnerLogos from "../components/partner-logos";
import SkfIcon from "../icons/SkfIcon";
import Reward from "../icons/Reward";
import {makeRequest} from "../api/fetcher";
import ReferencesSlider from "../components/referencesSlider";

const Index = ({ t }) => {
    const { i18n } = useTranslation();
    const locale = i18n.language;
    const offers = useSelector(storageSelectors.offers);
    const [references, setReferences] = useState([]);

    const offerText = (offer) => {
        return i18n.language === 'et'
            ? offer.nameEt
            : i18n.language === 'en'
                ? offer.nameEn : offer.nameFi;
    }

    const services = useSelector(storageSelectors.services);
    const serviceText = (service) => {
        return i18n.language === 'et'
            ? service.nameEt
            : i18n.language === 'en'
                ? service.nameEn : service.nameFi;
    }

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    };

    useEffect(() => {
        const draggonItems = document.querySelectorAll('[data-draggon]');
        draggonItems.forEach((draggonItem, key) => {
            draggonItem.dataset.draggon = `${key}`;
            const draggon = new Draggon(`[data-draggon="${key}"]`);
            draggon.bindEvents();
        });

        if (document.title === '') {
            document.title = "Elektrimasinad";
        }

        const fetchReferences = async () => {
            const references = await makeRequest({
                method: 'get',
                url: `/api/references`
            });

            setReferences(references.data ? Object.values(references.data) : []);
        };

        fetchReferences();
    }, [])

    return (
        <Layout>
            <Header/>
            <div className="layout__inner index-page">
                <div className="index-page__slider-container">
                    <Swiper
                        direction={'vertical'}
                        pagination={pagination}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper"
                        autoplay={{
                            "delay": 5000,
                            "disableOnInteraction": false
                        }}
                    >
                        {
                            offers.map((offer, key) => {
                                return (
                                    <SwiperSlide key={key} className={"index-page__swiper-slide"}>
                                        <p className="index-page__swiper-slide-text" dangerouslySetInnerHTML={{ __html: offerText(offer) }} />
                                        <span className={`index-page__image-wrapper _${key}`}>
                                                <img src={"/uploads/la/" + offer.image}
                                                     alt={offerText(offer)}
                                                     loading='lazy'
                                                     className="index-page__swiper-slide-image"/>
                                            <span className="index-page__image-reward">
                                                <Reward/>
                                            </span>
                                        </span>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
                <PartnerLogos className={'partner-logo-container_index-mobile'}/>
                <div className="index-page__services">
                    <div className="index-page__services-swipe-icons">
                        <span className="index-page__services-swipe-icon-left" data-draggon-nav-left=""><ArrowLeft/></span>
                        <span className="index-page__services-swipe-icon-right" data-draggon-nav-right=""><ArrowRight/></span>
                    </div>
                    <ul className="index-page__service-list" data-draggon="">
                        {
                            services.map((service, key) => {
                                return (
                                    <li key={key} className="index-page__service">
                                        <Link to={ prefixPath(`/services/${service.slug}`, locale)} title={serviceText(service)}
                                              className="index-page__service-link">
                                            <span className="index-page__service-icon">
                                                <GetServiceIconByKey serviceSlug={service.slug}/>
                                            </span>
                                            <span className="index-page__service-text">{serviceText(service)}</span>
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>

                <div className="index-page__references-wrapper">
                    <ReferencesSlider references={references} />
                </div>

                <a href="https://www.skf.com" title="SKF" target="_blank" rel="nofollow" className="index-page__skf-icon">
                    <SkfIcon/>
                </a>
            </div>
            <Footer/>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};

const mapDispatchToProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Index));