import * as React from "react";

export function GetEmployeeAvatar(employee) {
    console.log(employee.name);
    switch(employee.name) {
        case 'Kalev Lehiste':
            return (
                '/static/images/base/employee/kalev.png'
            );
        case 'Jüri Kotelevski':
            return (
                '/static/images/base/employee/juri.png'
            );
        case 'Dmitri Kotelevski':
            return (
                '/static/images/base/employee/dmitri.png'
            );
        default:
            return ('');
    }
}