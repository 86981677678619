import {prefixPath} from "./helpers";

export function localizeRoutes(routes, locales) {
  const result = [];

  locales.forEach((locale, index) => {
    routes.forEach((route, index) => {
      result.push(
          {
            ...route,
            path: prefixPath(route.path, locale)
          }
      );
    });
  });

  return result;
}