import React, {useEffect} from 'react'
import Layout from "../components/Layout"
import Header from '../components/Header'
import Footer from '../components/Footer'
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Breadcrumbs from "../components/breadcrumbs";
import store from "../store";
import {setBreadcrumbs} from "../actions";

const Feedback = ({ t }) => {
    useEffect(() => {
        store.dispatch(setBreadcrumbs({'pageName': t('feedback')}));
    }, [])

    return (
        <Layout>
            <Header/>
            <div className="layout__inner layout__inner_service">
                <Breadcrumbs/>
                <h1 className="layout__h1">
                    {t('feedback')}
                </h1>
            </div>
            <Footer/>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};

const mapDispatchToProps = {

};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Feedback));