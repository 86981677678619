import React, {useEffect, useState} from 'react'
import Layout from "../components/Layout"
import Header from '../components/Header'
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import Footer from "../components/footer";
import Breadcrumbs from "../components/breadcrumbs";
import store from "../store";
import {setBreadcrumbs} from "../actions";
import {makeRequest} from "../api/fetcher";
import {getBackgroundImage} from "../icons/serviceBgByService";
import {GetEmployeeAvatar} from "../icons/EmployeeAvatar";

const Contacts = ({ t }) => {
    const { i18n } = useTranslation();
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        const fetchEmployees = async () => {
            const employees = await makeRequest({
                method: 'get',
                url: `/api/employees`
            });

            setEmployees(employees.data ? Object.values(employees.data) : []);
            store.dispatch(setBreadcrumbs({'pageName': t('contacts')}));
        };

        fetchEmployees();
    }, [])

    const getEmployeePost = (employee) => {
        return i18n.language === 'et'
            ? employee.postEt
            : i18n.language === 'en'
                ? employee.postEn : employee.postFi;
    }

    return (
        <Layout>
            <Header/>
            <div className="layout__inner layout__inner_service">
                <Breadcrumbs/>
                <h1 className="layout__h1">
                    {t('contacts')}
                </h1>
                <div className="contacts">
                    <ul className="contacts__list">
                        {
                            employees.map((employee, key) => {
                                return (
                                    <li className="contacts__item" key={key}>
                                        <div className="contacts__image-container">
                                            <img src={`${GetEmployeeAvatar(employee)}`}
                                                 className="contacts__image"/>
                                        </div>
                                        <div className="contacts__info">
                                           <li className="contacts__name">{employee.name}</li>
                                            <li className="contacts__post">{getEmployeePost(employee)}</li>
                                            <li className="contacts__phone">{employee.phone}</li>
                                            <li className="contacts__email">
                                                <a href={`mailto:${employee.email}`}>{employee.email}</a>
                                            </li>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <Footer/>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};

const mapDispatchToProps = {

};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Contacts));