import * as React from "react";

export function GetServiceIconByKey({serviceSlug}) {
    switch(serviceSlug) {
        default:
        case 'repair-and-maintenance-of-electric-motors':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><path fill="#266DF0" d="M15.773 2.708A9.163 9.163 0 0 0 9.247 0c-1.374 0-2.76.317-3.99.898l-1.69.806 5.667 5.667c.25.251.383.581.383.938s-.132.687-.383.938c-.502.502-1.374.502-1.863 0L1.704 3.58.898 5.27c-1.677 3.528-.964 7.755 1.797 10.516a9.193 9.193 0 0 0 6.539 2.708c.647 0 1.281-.066 1.902-.198L22.84 30l1.863-1.863L13.83 17.265a9.006 9.006 0 0 0 1.942-1.479 9.82 9.82 0 0 0 1.48-1.942l10.871 10.872 1.863-1.863L18.27 11.136c.607-2.946-.225-6.143-2.51-8.428h.013ZM13.91 13.923a6.574 6.574 0 0 1-4.676 1.929 6.597 6.597 0 0 1-4.677-1.929 6.596 6.596 0 0 1-1.862-5.6l2.8 2.8c1.493 1.493 4.109 1.493 5.601 0a3.927 3.927 0 0 0 1.163-2.8c0-1.057-.41-2.048-1.163-2.801l-2.8-2.8a6.623 6.623 0 0 1 5.601 1.862 6.61 6.61 0 0 1 0 9.34h.013Z"/></svg>
            );
        case 'maintenance-contracts':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="none"><g fill="#266DF0" clipPath="url(#vperv8ss)"><path d="m26.178 10.16 1.8-3.012-5.126-5.126-3.013 1.8c-.339-.157-.678-.3-1.017-.418L17.974 0h-3.626v2.609h1.591l.705 2.843.717.209c.665.196 1.304.47 1.93.796l.653.352 2.504-1.5 2.244 2.243-1.514 2.518.353.652c.326.6.587 1.252.795 1.93l.209.705 2.843.704v3.183l-2.843.717-.209.717c-.195.665-.47 1.318-.795 1.93l-.353.653 1.514 2.517-2.244 2.244-2.517-1.513-.653.352c-.613.326-1.252.6-1.917.796l-.717.208-.718 2.844h-1.591v2.608h3.626l.848-3.404c.339-.13.691-.26 1.017-.417l3.013 1.8 5.126-5.126-1.8-3.014c.157-.326.3-.678.418-1.017l3.404-.848V12.04l-3.404-.848c-.13-.352-.261-.691-.418-1.017l.013-.013Z"/><path d="M14.348 19.565v2.609c3.6 0 6.521-2.922 6.521-6.522S17.948 9.13 14.348 9.13v2.61a3.925 3.925 0 0 1 3.913 3.912 3.925 3.925 0 0 1-3.913 3.913ZM10.435 1.304H5.987L0 7.291V30h10.435v1.304h2.608V0h-2.608v1.304ZM6.522 4.448v3.378H3.143l3.379-3.378ZM2.609 27.39V10.435H9.13V3.913h1.305v23.478H2.609Z"/></g><defs><clipPath id="vperv8ss"><path fill="#fff" d="M0 0h30v31.304H0z"/></clipPath></defs></svg>
            );
        case 'sale-of-new-electric-motors':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 28 30" fill="none"><path fill="#266DF0" d="M11.454 18.387V22.5H7.636V30h12.728v-7.5h-3.819v-4.113a8.934 8.934 0 0 0 5.117-3.937L28 10.713V1.25h-3.818V0H3.818v1.25H0v9.463l6.338 3.737a8.934 8.934 0 0 0 5.116 3.938Zm6.364 9.113h-7.636V25h7.636v2.5ZM22.91 6.762l1.273-1.25V3.75h1.273v5.537l-2.584 1.525c.025-.274.038-.537.038-.812V6.763ZM6.364 2.5h15.272v1.987L21.115 5h-8.388v2.5h7.637V10c0 .963-.242 1.85-.624 2.662h-.025v.025a6.362 6.362 0 0 1-11.43 0l-.025-.024A6.035 6.035 0 0 1 7.636 10V5.737l-1.272-1.25V2.5ZM2.545 9.287V3.75h1.273v1.775l1.273 1.25v3.237c0 .275.013.55.038.813L2.545 9.3v-.013Z"/></svg>
            );
        case 'diagnostics-of-industrial-equipment':
            return (
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_10_946)">
                        <path d="M30 27.2727H0V30H30V27.2727Z" fill="#266DF0"/>
                        <path d="M5.45481 17.7273H2.72754V25.9091H5.45481V17.7273Z" fill="#266DF0"/>
                        <path d="M10.9089 21.8182H8.18164V25.9091H10.9089V21.8182Z" fill="#266DF0"/>
                        <path d="M16.364 5.45456H13.6367V25.9091H16.364V5.45456Z" fill="#266DF0"/>
                        <path d="M21.8181 10.9091H19.0908V25.9091H21.8181V10.9091Z" fill="#266DF0"/>
                        <path d="M27.2732 0H24.5459V25.9091H27.2732V0Z" fill="#266DF0"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_10_946">
                            <rect width="30" height="30" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>

            );
        case 'repair-and-maintenance-of-industrial-equipment':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none"><g fill="#266DF0" clipPath="url(#evrleuc23)"><path d="M0 23.782c0 .75.286 1.459.818 1.977l2.046 2.046c.531.531 1.24.818 1.99.818s1.46-.287 1.978-.819l6.736-6.668 1.091 1.091 1.923-1.922-3.123-3.123 1.677-1.677 5.973 5.972a4.743 4.743 0 0 0-.655 2.387 4.778 4.778 0 0 0 4.773 4.772c.56 0 1.091-.109 1.582-.286l-3.627-3.64 2.89-2.892 3.628 3.627a4.712 4.712 0 0 0 .286-1.581 4.778 4.778 0 0 0-4.772-4.773 4.85 4.85 0 0 0-2.128.518l-6.04-6.04 7.786-7.787h1.854l1.923-3.86L26.686 0l-3.859 1.923v1.922l.041.041-11.359 11.36-3.123-3.123-1.922 1.923 1.077 1.077-6.723 6.682A2.795 2.795 0 0 0 0 23.794v-.013Zm9.477-6.723 2.141 2.141-6.832 6.682-2.045-2.155 6.736-6.668Z"/><path d="M1.65 3.19a4.712 4.712 0 0 0-.287 1.583 4.778 4.778 0 0 0 4.773 4.772c.873 0 1.677-.245 2.386-.654l3.45 3.45 1.923-1.923-3.504-3.504a4.67 4.67 0 0 0 .518-2.128A4.778 4.778 0 0 0 6.136.014c-.56 0-1.09.109-1.582.286l3.627 3.627-2.89 2.891L1.65 3.191Z"/></g><defs><clipPath id="evrleuc23"><path fill="#fff" d="M0 0h30v28.636H0z"/></clipPath></defs></svg>
            );
        case 'grandfos-authorized-service-partner':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><g fill="#266DF0" clipPath="url(#wevok438)"><path d="M22.463 16.17v-2.488h-2.662a5.145 5.145 0 0 0-.522-1.269l1.89-1.89-1.753-1.754-1.89 1.89a5.183 5.183 0 0 0-1.27-.535V7.463H13.77v2.661c-.448.112-.87.299-1.269.523l-1.89-1.89-1.754 1.753 1.89 1.89c-.236.399-.41.821-.522 1.27H7.562v2.487h2.662c.112.448.299.87.523 1.268l-1.891 1.89 1.754 1.755 1.89-1.89c.398.235.821.41 1.269.521v2.662h2.487v-2.662c.448-.111.871-.298 1.27-.522l1.89 1.89 1.753-1.753-1.89-1.89c.236-.399.41-.821.522-1.27h2.662v.013ZM15 17.412a2.495 2.495 0 0 1-2.487-2.488A2.495 2.495 0 0 1 15 12.438a2.495 2.495 0 0 1 2.488 2.487A2.495 2.495 0 0 1 15 17.413Z"/><path d="M14.925 2.488c4.304 0 8.184 2.176 10.46 5.733l-2.997 2.973h7.537V3.731l-2.724 2.7C24.441 2.412 19.913 0 14.925 0 6.692 0 0 6.692 0 14.925h2.488c0-6.853 5.584-12.437 12.437-12.437ZM15.074 27.363c-4.303 0-8.184-2.177-10.46-5.734l2.998-2.972H.074v7.462l2.724-2.699c2.761 4.018 7.289 6.43 12.276 6.43C23.308 29.85 30 23.16 30 14.926h-2.488c0 6.854-5.585 12.438-12.438 12.438Z"/></g><defs><clipPath id="wevok438"><path fill="#fff" d="M0 0h30v29.851H0z"/></clipPath></defs></svg>
            );
        case 'thermographic-analysis':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><g fill="#266DF0" clipPath="url(#ropwvk28)"><path d="M29.062 26.42H3.184l6.063-6.063 2.642 2.642 9.247-9.247 2.642 2.642 5.562-5.549-1.876-1.875-3.686 3.698-2.642-2.642-9.247 9.247-2.642-2.642-6.605 6.605v-8.163l5.284-5.284 5.667 5.68 1.876-1.876-7.543-7.53-5.284 5.284V0H0v29.062h29.062V26.42Z"/><path d="m22.457 20.594-3.026-3.038-1.875 1.876 4.9 4.888L30 16.79l-1.876-1.876-5.667 5.68Z"/></g><defs><clipPath id="ropwvk28"><path fill="#fff" d="M0 0h30v29.062H0z"/></clipPath></defs></svg>
            );
        case 'balancing':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none"><g clipPath="url(#weovme8)"><path fill="#266DF0" d="M24.038 3.75H25v-2.5h-8.75V0h-2.5v1.25H5v2.5h.963l-4.288 10H0V15c0 3.45 2.8 6.25 6.25 6.25s6.25-2.8 6.25-6.25v-1.25h-1.675l-4.287-10h7.212V22.5H12.5A2.507 2.507 0 0 0 10 25v1.25H8.75v2.5h12.5v-2.5H20V25c0-1.375-1.125-2.5-2.5-2.5h-1.25V3.75h7.212l-4.287 10H17.5V15c0 3.45 2.8 6.25 6.25 6.25S30 18.45 30 15v-1.25h-1.675l-4.287-10Zm-17.788 15a3.767 3.767 0 0 1-3.538-2.5h7.075a3.767 3.767 0 0 1-3.537 2.5Zm-1.85-5 1.85-4.325L8.1 13.75H4.388 4.4ZM17.5 25v1.25h-5V25h5Zm6.25-15.575 1.85 4.325h-3.713l1.85-4.325h.013Zm0 9.325a3.767 3.767 0 0 1-3.538-2.5h7.076a3.767 3.767 0 0 1-3.538 2.5Z"/></g><defs><clipPath id="weovme8"><path fill="#fff" d="M0 0h30v28.75H0z"/></clipPath></defs></svg>
            );
        case 'alignment':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none"><g fill="#266DF0" clipPath="url(#vpwevk7)"><path d="M24.545 15H30v-2.727h-5.455V8.182l-5.454 5.523 5.454 5.509V15ZM5.455 19.214l5.454-5.51-5.454-5.522v4.09H0V15h5.455v4.214ZM16.364 0h-2.727v27.273h2.727V0Z"/></g><defs><clipPath id="vpwevk7"><path fill="#fff" d="M0 0h30v27.273H0z"/></clipPath></defs></svg>
            );
        case '24-7-hotline':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><g fill="#266DF0" clipPath="url(#gepr7ge)"><path d="M29.062 26.42H3.184l6.063-6.063 2.642 2.642 9.247-9.247 2.642 2.642 5.562-5.549-1.876-1.875-3.686 3.698-2.642-2.642-9.247 9.247-2.642-2.642-6.605 6.605v-8.163l5.284-5.284 5.667 5.68 1.876-1.876-7.543-7.53-5.284 5.284V0H0v29.062h29.062V26.42Z"/><path d="m22.457 20.594-3.026-3.038-1.875 1.876 4.9 4.888L30 16.79l-1.876-1.876-5.667 5.68Z"/></g><defs><clipPath id="gepr7ge"><path fill="#fff" d="M0 0h30v29.062H0z"/></clipPath></defs></svg>
            );
        case 'teollisuuden-laitteiden-siirtopalvelu':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none"><g fill="#266DF0" clipPath="url(#gsdfgsd)"><path d="m6.253 29.536.965-1.165c.877-1.065 5.276-6.529 5.276-9.587a6.263 6.263 0 0 0-6.253-6.253C2.794 12.531 0 15.338 0 18.784c0 3.058 4.399 8.522 5.288 9.587l.965 1.165Zm0-14.498A3.749 3.749 0 0 1 10 18.784c0 1.341-1.955 4.424-3.747 6.767-1.78-2.343-3.747-5.426-3.747-6.767a3.749 3.749 0 0 1 3.747-3.746Z"/><path d="M6.228 20.05a1.253 1.253 0 1 0 0-2.506 1.253 1.253 0 0 0 0 2.506ZM23.759 0a6.263 6.263 0 0 0-6.253 6.253c0 3.058 4.398 8.521 5.288 9.587l.965 1.165.965-1.165C25.6 14.774 30 9.31 30 6.253A6.263 6.263 0 0 0 23.747 0h.012Zm0 13.02c-1.78-2.343-3.747-5.426-3.747-6.767a3.749 3.749 0 0 1 3.747-3.747 3.749 3.749 0 0 1 3.747 3.747c0 1.341-1.955 4.424-3.747 6.767Z"/><path d="M23.735 7.519a1.253 1.253 0 1 0 0-2.506 1.253 1.253 0 0 0 0 2.506ZM22.518 27.569H11.24v2.506h13.785V20.05h-2.507v7.519Z"/></g><defs><clipPath id="gsdfgsd"><path fill="#fff" d="M0 0h30v30.075H0z"/></clipPath></defs></svg>
            );
        case 'fieldwork-on-the-clients-object':
            return (
                <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.72999 15.7309L0 19.117L1.9407 20.8788L3.71623 19.267V27.501C3.71623 28.8755 4.95497 30 6.46898 30H20.2328C21.7468 30 22.9855 28.8755 22.9855 27.501V19.267L24.7611 20.8788L26.7018 19.117L22.9718 15.7309V2.49896C22.9718 1.12453 21.733 0 20.219 0H6.48275C4.96873 0 3.72999 1.12453 3.72999 2.49896V15.7309ZM6.48275 27.4885V24.9896H20.2465V27.4885H6.48275ZM20.2465 22.4906H11.9883V18.7422H9.23551V22.4906H6.48275V16.7555L13.3646 10.5081L20.2465 16.7555V22.4906ZM13.3646 6.98459L6.48275 13.232V6.2474H20.2465V13.232L13.3646 6.98459ZM20.2465 2.49896V3.74844H6.48275V2.49896H20.2465Z" fill="#266DF0"/>
                    <path d="M17.494 16.2432H14.7412V18.7422H17.494V16.2432Z" fill="#266DF0"/>
                </svg>
            );
    }
}