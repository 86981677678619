import React from "react";
import { connect } from "react-redux";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { withTranslation } from "react-i18next";
import routes from './routes'
import NotFound from "./pages/404";

const App = ({ t }) => {
    return (
        <Router>
            <Routes>
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        exact="true"
                        element={route.element}
                    />
                ))}
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Router>
    );
};

const mapStateToProps = (state) => {
    return {
        'settings': state.settings,
        'offers': state.offers,
        'services': state.services
    };
};

const mapDispatchToProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));