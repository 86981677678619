import * as React from "react";

export default function GrundfosSecondIcon() {
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 794 508">
            <defs>
                <style>{`
                    .cls-1 {
                    fill: #1b3866;
                    stroke-width: 0px;
                }

                    .cls-2 {
                    font - size: 74.22px;
                }

                    .cls-grs-3 {
                    fill: #fff;
                    font-family: Inter-SemiBold, Inter;
                    font-variation-settings: 'wght' 600, 'slnt' 0;
                    font-weight: 600;
                    font-size: 70px;
                }

                    .cls-4 {
                    font - size: 90px;
                }
                    `}</style>
            </defs>
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <rect className="cls-1" width="794" height="508"/>
                    <text className="cls-grs-3" transform="translate(54.98 203.85)">
                        <tspan className="cls-4">
                            <tspan x="0" y="0">GRUNDFOS</tspan>
                        </tspan>
                        <tspan className="cls-2">
                            <tspan x="0" y="78">VOLITATUD</tspan>
                        </tspan>
                        <tspan className="cls-2">
                            <tspan x="0" y="156">HOOLDUSPARTNER</tspan>
                        </tspan>
                    </text>
                </g>
            </g>
        </svg>
    );
}