import React, {useEffect} from 'react'
import Layout from "../components/Layout"
import Header from '../components/Header'
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Footer from "../components/footer";
import store from "../store";
import {setBreadcrumbs} from "../actions";
import Breadcrumbs from "../components/breadcrumbs";
import {GetEmployeeAvatar} from "../icons/EmployeeAvatar";

const About = ({ t }) => {
    useEffect(() => {
        store.dispatch(setBreadcrumbs({'pageName': t('about')}));
    }, [])

    return (
        <Layout>
            <Header/>
            <div className="layout__inner layout__inner_service">
                <Breadcrumbs/>
                <h1 className="layout__h1">
                    {t('about')}
                </h1>
                <div className="about">
                    <div className="about__description">
                        {t('about_description_first')}
                    </div>
                    <div className="about__caption">
                        {t('about_caption_first')}
                    </div>
                    <div className="about__description about__description_margin-top">
                        <p dangerouslySetInnerHTML={{__html: t(`about_description_second`) }} />
                    </div>
                    <div className="about__caption">
                        {t('about_caption_second')}
                        <img src={'/static/images/base/about/landscape.png'} alt={t('about_caption_second')} className="about__logo"/>
                    </div>
                    <p dangerouslySetInnerHTML={{__html: t(`about_description_list`) }} />
                </div>
            </div>
            <Footer/>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};

const mapDispatchToProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(About));