import * as React from "react";

export default function SkfIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="744.09448" height="201.96851" viewBox="0 0 744 201" id="svg1877">
            <defs id="defs1879" />
            <g transform="translate(-123.3975,-390.9898)" id="layer1">
                <g transform="matrix(1.33622,0,0,1.33622,-23.77217,-169.7479)" id="g1892">
                    <path d="M 143.43837,495.46364 C 140.99419,495.46364 139.039,493.01982 139.039,490.57565 L 139.039,437.29671 C 139.039,435.34151 140.99419,432.89734 143.43837,432.89734 L 259.28395,432.89734 C 261.23915,432.89734 263.68297,435.34151 263.68297,437.29671 L 263.68297,459.2925 C 263.68297,461.73667 261.23915,464.18049 259.28395,464.18049 L 221.64624,464.18049 C 219.20207,464.18049 216.75825,461.73667 216.75825,459.2925 L 216.75825,451.47171 C 216.75825,450.00549 215.29167,448.53892 213.82545,448.53892 L 188.89652,448.53892 C 187.43029,448.53892 185.4751,450.00549 185.4751,451.47171 L 185.4751,476.40065 C 185.4751,477.86687 187.43029,479.82206 188.89652,479.82206 L 274.43655,479.82206 C 277.85832,479.82206 279.32454,481.28864 279.32454,484.22143 L 279.32454,553.1423 C 279.32454,555.58612 276.88073,557.54131 274.43655,557.54131 L 127.79679,557.54131 C 125.35262,557.54131 123.39742,555.58612 123.39742,553.1423 L 123.39742,515.50458 C 123.39742,513.06041 125.35262,511.10521 127.79679,511.10521 L 165.43415,511.10521 C 167.87833,511.10521 169.83352,513.06041 169.83352,515.50458 L 169.83352,538.96695 C 169.83352,540.43352 171.78872,541.89974 173.25494,541.89974 L 229.46703,541.89974 C 230.9336,541.89974 232.39982,540.43352 232.39982,538.96695 L 232.39982,498.39643 C 232.39982,496.93021 230.9336,495.46364 229.46703,495.46364 L 143.43837,495.46364 L 143.43837,495.46364 z " fill="#039" id="path1890" />
                    <path d="M 310.60769,482.75486 L 310.60769,507.6838 C 310.60769,509.15002 309.14147,510.12762 307.18628,511.10521 C 304.25348,511.59419 302.78691,513.06041 302.78691,515.50458 L 302.78691,553.1423 C 302.78691,555.58612 304.7421,557.54131 307.18628,557.54131 L 352.64478,557.54131 C 355.0886,557.54131 357.04379,555.58612 357.04379,553.1423 L 357.04379,511.59419 C 357.04379,511.10521 358.02139,510.61659 358.51037,511.10521 L 402.99092,556.56372 C 403.96852,557.54131 404.45749,557.54131 405.92371,557.54131 L 461.64718,557.54131 C 464.091,557.54131 466.53517,555.58612 466.53517,553.1423 L 466.53517,515.50458 C 466.53517,513.06041 464.091,511.10521 461.64718,511.10521 L 421.07667,511.10521 C 420.09907,511.10521 419.61009,511.10521 419.12147,510.12762 L 388.81592,480.31104 C 388.81592,479.82206 388.32694,479.82206 388.81592,479.33344 L 403.4799,464.66947 C 403.96852,464.18049 404.45749,464.18049 405.43509,464.18049 L 446.49423,464.18049 C 448.44942,464.18049 450.8936,461.73667 450.8936,459.2925 L 450.8936,437.29671 C 450.8936,435.34151 448.44942,432.89734 446.49423,432.89734 L 405.92371,432.89734 C 404.45749,432.89734 403.96852,433.38632 402.99092,434.36392 L 358.99899,479.33344 C 358.02139,479.82206 357.04379,479.82206 357.04379,478.84447 L 357.04379,437.29671 C 357.04379,435.34151 355.0886,432.89734 352.64478,432.89734 L 307.18628,432.89734 C 304.7421,432.89734 302.78691,435.34151 302.78691,437.29671 L 302.78691,474.93407 C 302.78691,477.37825 304.25348,478.84447 306.6973,479.33344 C 309.63009,480.31104 310.60769,481.28864 310.60769,482.75486 L 310.60769,482.75486 z " fill="#039" id="path1888" />
                    <path d="M 489.99753,553.1423 C 489.99753,555.58612 492.44135,557.54131 494.88552,557.54131 L 539.85505,557.54131 C 542.29887,557.54131 544.74304,555.58612 544.74304,553.1423 L 544.74304,515.99321 C 544.74304,513.54939 543.27646,511.59419 540.34367,511.10521 C 537.89985,510.12762 536.92225,509.63899 536.92225,507.6838 L 536.92225,498.39643 C 536.92225,496.93021 538.38847,495.46364 539.85505,495.46364 L 556.9632,495.46364 C 558.91839,495.46364 560.38461,496.93021 560.38461,498.39643 C 560.38461,500.84061 562.82879,503.28443 564.78398,503.28443 L 602.42134,503.28443 C 604.86552,503.28443 607.30934,500.84061 607.30934,498.39643 L 607.30934,476.40065 C 607.30934,473.95647 604.86552,472.00128 602.42134,472.00128 L 564.78398,472.00128 C 562.82879,472.00128 560.38461,473.95647 560.38461,476.40065 C 560.38461,477.86687 558.91839,479.82206 556.9632,479.82206 L 539.85505,479.82206 C 538.38847,479.82206 536.92225,477.86687 536.92225,476.40065 L 536.92225,451.47171 C 536.92225,450.00549 538.38847,448.53892 539.85505,448.53892 L 588.24634,448.53892 C 589.71257,448.53892 591.66776,450.00549 591.66776,451.47171 L 591.66776,459.2925 C 591.66776,461.73667 593.62296,464.18049 596.06713,464.18049 L 649.34607,464.18049 C 651.79024,464.18049 653.74544,461.73667 653.74544,459.2925 L 653.74544,437.29671 C 653.74544,435.34151 651.79024,432.89734 649.34607,432.89734 L 494.88552,432.89734 C 492.44135,432.89734 489.99753,435.34151 489.99753,437.29671 L 489.99753,474.93407 C 489.99753,477.37825 491.95273,478.84447 494.39655,479.33344 C 496.35174,479.82206 497.81832,481.28864 497.81832,482.75486 L 497.81832,507.6838 C 497.81832,509.15002 496.84072,510.61659 494.39655,511.10521 C 491.46375,511.59419 489.99753,513.06041 489.99753,515.50458 L 489.99753,553.1423 L 489.99753,553.1423 z " fill="#039" id="path6" />
                </g>
            </g>
        </svg>
    );
}