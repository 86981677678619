import React from 'react';
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from './src/App';
import store from './src/store';
import { Provider } from 'react-redux';
import {getOffers, getServices, getSettings} from "./src/actions";
import './src/utils/i18n';

if (document.getElementById('app')) {
    const rootElement = document.getElementById("app");
    const root = createRoot(rootElement);

    store.dispatch(getSettings());
    store.dispatch(getOffers());
    store.dispatch(getServices());

    root.render(
        <StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </StrictMode>
    );
}