require('../fonts/CeraPro/stylesheet.css');
require('../scss/app.scss');
require('./svg/svg.js');
require('./common/webp-polyfill.js');
require('./common/true-vh.js');
require('../react');

// require('./common/goal.js');
// require('./common/form-validation.js');
// require('./common/links.js');
// require('./common/main.js');
// require('./common/pagination.js');
// require('./common/map.js');
// require('./common/need-animation.js');
// require('./field/phone.js');
// require('../components/draggon/draggon');