import React from 'react'
import { connect } from 'react-redux';
import Layout from "../components/Layout"
import Header from '../components/Header'
import {withTranslation} from "react-i18next";
import Footer from "../components/footer";

const Services = ({ t }) => {
    return (
        <Layout>
            <Header/>
            <div className="layout__inner">
                <h1 className="layout__h1">
                    {t('services')}
                </h1>
            </div>
            <Footer/>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};

const mapDispatchToProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Services));