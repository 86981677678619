import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
SwiperCore.use([Autoplay, Navigation]);
import 'swiper/swiper-bundle.css';
import {GetPreferencesIconByKey} from "../icons/getPreferencesIcon";


const ReferencesSlider = ({ references }) => {
    useEffect(() => {
        setTimeout(() => {
            document.querySelector('.swiper-button-next').click();
        }, 200);
    }, []);

    return (
        <div className="swiper-container">
            <Swiper
                direction={'horizontal'}
                spaceBetween={40}
                pagination={false}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                modules={[Navigation, Autoplay]}
                className="mySwiper references-main-page"
                loop={true}
                slidesPerView={9}
                speed={8000}
                autoplay={{
                    delay: 0,
                    disableOnInteraction: false
                }}
            >
                {references.map((reference, key) => (
                    <SwiperSlide key={key} className={"index-page__references-swiper-slide"}>
                        <span className="index-page__references-swiper-image-wrapper">
                            <span className="index-page__references-swiper-image">
                              <GetPreferencesIconByKey preferenceKey={key} />
                            </span>
                        </span>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
        </div>
    );
};

export default ReferencesSlider;