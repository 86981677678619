import * as React from "react";

export default function FemIcon() {
    return (
        <svg width="280" height="70" viewBox="0 0 280 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_806_1607)">
                <path d="M146.429 69.9971C112.58 69.9971 78.728 70.0029 44.8789 69.9914C39.3481 69.9914 33.8375 69.4454 28.3211 69.1133C27.2438 69.0497 26.1694 68.628 25.1383 68.2525C15.0096 64.5668 6.84478 58.5327 2.74072 48.2814C-2.70634 34.6852 -0.444916 19.0006 13.2449 8.25826C17.4732 4.93937 22.1289 2.39171 27.1572 0.514183C27.9687 0.21089 28.7919 -0.00285958 29.6814 2.89228e-05C43.2875 0.057799 56.8965 0.109792 70.5026 0.144454C92.5247 0.199336 114.547 0.236886 136.569 0.288879C169.121 0.36398 201.674 0.444859 234.226 0.525737C237.591 0.534402 240.958 0.606615 244.323 0.554622C247.168 0.511294 249.837 1.26808 252.476 2.16352C260.861 5.00292 268.153 9.41944 273.335 16.7909C276.904 21.8718 278.684 27.5795 279.01 33.7378C279.359 40.3813 278.64 46.8747 275.613 52.9116C270.735 62.6401 262.498 67.8077 252.011 69.6159C249.533 70.0434 246.925 69.7198 244.378 69.7198C217.019 69.7343 189.659 69.743 162.3 69.7545C157.011 69.7545 151.72 69.7545 146.432 69.7545C146.432 69.8325 146.432 69.9134 146.432 69.9914L146.429 69.9971ZM138.117 4.9827C122.637 4.9827 107.159 5.00003 91.6785 4.97692C76.7294 4.9567 61.7775 4.98847 46.8284 4.78339C43.0218 4.7314 39.6369 5.58928 36.3502 7.29639C34.0887 8.47201 31.8937 9.75162 29.8403 11.2652C19.3129 19.0122 15.1482 30.1011 18.201 42.6343C20.5115 52.1173 26.6748 58.42 35.2758 62.5852C37.6094 63.7146 39.995 64.8498 42.6117 65.0376C47.2587 65.3727 51.9202 65.7135 56.5759 65.7251C79.1699 65.7857 101.767 65.7655 124.361 65.7193C161.329 65.6442 198.295 65.803 235.263 65.5084C242.541 65.4506 248.638 62.7066 253.84 57.8828C259.067 53.0359 261.355 46.7562 261.918 39.8036C262.848 28.3334 259.116 18.8908 249.528 12.0855C245.611 9.30679 241.285 7.38593 236.667 6.10921C235.234 5.71349 233.807 5.30621 232.294 5.3091" fill="white"/>
                <path d="M223.835 16.2392C225.562 21.9094 227.205 27.3109 228.857 32.7095C230.859 39.2548 232.843 45.8088 234.896 52.3369C235.263 53.5038 235.078 53.8187 233.834 53.8071C229.363 53.7609 224.892 53.7638 220.424 53.8504C219.378 53.8706 219.017 53.5182 218.781 52.5766C217.752 48.4923 216.658 44.4252 215.586 40.3524C215.442 39.8094 215.274 39.2721 215.084 38.6193C214.495 38.8562 214.336 39.327 214.096 39.6939C211.768 43.2698 209.455 46.8574 207.136 50.4391C206.537 51.3615 205.903 51.3586 205.235 50.4304C202.517 46.6523 199.805 42.8741 197.076 39.1046C196.805 38.7262 196.62 38.2554 196.005 37.9723C195.594 39.353 195.184 40.6991 194.794 42.0509C193.778 45.5922 192.744 49.1306 191.773 52.6864C191.537 53.5587 191.181 53.9082 190.214 53.9053C186.624 53.8909 186.627 53.9342 187.681 50.5315C190.924 40.0607 194.162 29.587 197.402 19.1162C197.541 18.6684 197.697 18.2265 197.853 17.7586C198.509 17.9088 198.696 18.4547 199 18.8418C202.89 23.7782 206.783 28.7118 210.619 33.6916C211.352 34.6448 211.655 34.5899 212.291 33.6165C215.751 28.3161 219.272 23.0561 222.772 17.7817C223.061 17.3484 223.364 16.9238 223.838 16.2363L223.835 16.2392Z" fill="white"/>
                <path d="M93.579 36.0542C93.579 30.7336 93.605 25.4158 93.5559 20.0952C93.5472 19.0236 93.761 18.6047 94.9596 18.6134C104.141 18.6654 113.32 18.6596 122.501 18.6192C123.607 18.6134 123.994 18.8907 124.006 20.0519C124.037 23.0502 124.098 23.0502 121.152 23.0502C117.837 23.0502 114.518 23.0877 111.203 23.0299C110.186 23.0126 109.909 23.3303 109.912 24.3298C109.923 27.4927 109.88 27.4927 113.051 27.4927C116.609 27.4927 120.164 27.5216 123.72 27.4725C124.676 27.458 125.069 27.6573 125.071 28.7174C125.077 31.6926 125.129 31.6926 122.128 31.6926C118.524 31.6926 114.917 31.7272 111.315 31.6723C110.29 31.6579 109.894 31.8659 109.906 33.001C109.969 38.2235 109.969 43.4488 109.906 48.6712C109.891 49.8237 110.316 49.9941 111.324 49.9826C115.506 49.9335 119.688 50.0028 123.867 49.9363C124.97 49.919 125.242 50.257 125.219 51.3171C125.164 53.7896 125.219 53.7925 122.801 53.7925C113.62 53.7925 104.441 53.7694 95.2599 53.8185C93.9805 53.8243 93.5386 53.5296 93.5588 52.1634C93.6426 46.7965 93.5935 41.4297 93.5935 36.06C93.5877 36.06 93.579 36.06 93.5732 36.06L93.579 36.0542Z" fill="white"/>
                <path d="M43.9022 36.2019C43.9022 30.835 43.9253 25.4711 43.882 20.1043C43.8734 19.0499 44.064 18.6051 45.277 18.6109C54.3574 18.6629 63.4406 18.6571 72.521 18.6167C73.5751 18.6109 73.9217 18.8622 73.9044 19.9656C73.8582 23.0447 73.913 23.0447 70.8227 23.0447C67.7468 23.0447 64.6709 23.0765 61.598 23.0274C60.6564 23.013 60.3618 23.3076 60.3503 24.2493C60.3041 27.4844 60.2694 27.4844 63.4377 27.4844C67.0883 27.4844 70.7418 27.5104 74.3925 27.467C75.2993 27.4555 75.6344 27.6692 75.6373 28.6456C75.6459 31.6843 75.6893 31.6843 72.6538 31.6843C69.1447 31.6843 65.6356 31.7709 62.1323 31.6438C60.5958 31.5861 60.3243 32.1146 60.3387 33.5069C60.4109 39.7836 60.3445 46.0603 60.3936 52.3342C60.4023 53.4434 60.1134 53.8218 58.9582 53.8044C54.3949 53.7438 49.8287 53.7524 45.2654 53.7986C44.2228 53.8102 43.8647 53.5445 43.8762 52.4382C43.9369 47.0251 43.9022 41.612 43.9022 36.1961V36.2019Z" fill="white"/>
                <path d="M143.524 36.1063C143.524 30.7366 143.552 25.3669 143.5 19.9971C143.489 18.9168 143.812 18.6049 144.887 18.6193C149.069 18.6742 153.254 18.6829 157.436 18.6164C158.611 18.5991 158.984 18.8995 158.978 20.1213C158.926 29.5176 158.972 38.9168 158.923 48.3131C158.917 49.5841 159.192 50.026 160.561 49.9942C164.694 49.8989 168.832 49.9942 172.965 49.9336C174.037 49.9191 174.369 50.2022 174.346 51.2912C174.294 53.784 174.355 53.784 171.799 53.784C162.903 53.784 154.008 53.7608 145.112 53.8099C143.861 53.8157 143.474 53.5038 143.492 52.2126C143.567 46.8429 143.524 41.4732 143.524 36.1034V36.1063Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_806_1607">
                    <rect width="279.091" height="70" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
}