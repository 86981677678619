import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const locales = ['et', 'en', 'fi'];

const resources = locales.reduce((acc, locale) => {
    try {
        const xml = require(`xliff-loader!/translations/messages.${locale}.lng`);
        acc[locale] = {
            translation: xml
        };
    } catch (e) {
        // console.log(e);
    }
    return acc;
}, {});

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: document.documentElement.lang ? document.documentElement.lang : 'et'
});

i18n
    .changeLanguage(document.documentElement.lang || 'et');

export const currentLocale = i18n.language;

export default i18n;