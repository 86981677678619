import React, {useEffect, useState} from 'react'
import Layout from "../components/Layout"
import Header from '../components/Header'
import { connect } from 'react-redux';
import {useTranslation, withTranslation} from "react-i18next";
import Footer from "../components/footer";
import Breadcrumbs from "../components/breadcrumbs";
import store from "../store";
import {setBreadcrumbs} from "../actions";
import {makeRequest} from "../api/fetcher";
import {singleBind} from "../../../components/dynamic/bind";
import {smartTab} from "../../../components/smart-tabs/smart-tabs";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
SwiperCore.use([Pagination]);
import Accordion from "../../../components/accordion/accordion";
import ArrowTop from "../icons/ArrowTop";
import ArrowLeft from "../icons/ArrowLeft";
import ArrowRight from "../icons/ArrowRight";
import Draggon from "../../../components/draggon/draggon";

const Gallery = ({ t }) => {
    const { i18n } = useTranslation();
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchGallery = async () => {
            const products = await makeRequest({
                method: 'get',
                url: `/api/gallery`
            });

            setProducts(products.data ? Object.values(products.data) : []);
            store.dispatch(setBreadcrumbs({'pageName': t('gallery')}));
        };

        fetchGallery();

        setTimeout(() => {
            document.querySelectorAll('.smart-tabs li a').forEach((link) => {
                singleBind(link, 'initialized', 'click', smartTab);
            })
            new Accordion();
        }, 500)

        const draggonItems = document.querySelectorAll('[data-draggon]');
        draggonItems.forEach((draggonItem, key) => {
            draggonItem.dataset.draggon = `${key}`;
            const draggon = new Draggon(`[data-draggon="${key}"]`);
            draggon.bindEvents();
        });
    }, [])

    const productNameByLang = (product) => {
        return i18n.language === 'et'
            ? product.nameEt
            : i18n.language === 'en'
                ? product.nameEn : product.nameFi;
    }

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    };

    return (
        <Layout>
            <Header/>
            <div className="layout__inner layout__inner_gallery">
                <Breadcrumbs/>
                <h1 className="layout__h1">
                    {t('gallery')}
                </h1>
                <div className="gallery smart-tabs">
                    <div className="gallery__swipe-icons">
                        <span className="gallery__swipe-icon-left" data-draggon-nav-left=""><ArrowLeft/></span>
                        <span className="gallery__swipe-icon-right" data-draggon-nav-right=""><ArrowRight/></span>
                    </div>
                    <ul className="gallery__tabs-link-list" data-draggon="">
                        {
                            products.map((product, key) => {
                                return (
                                    <li className={`gallery__tab-item ${key === 0 ? '_active' : ''}`} key={key}>
                                        <a href={`#product-${key}`} className="gallery__tab-link">
                                            {productNameByLang(product)}
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <ul className="gallery__list" data-accordion-container="">
                        {
                            products.map((product, key) => {
                                return (
                                    <li className={`gallery__item smart-content ${key === 0 ? '_active' : ''}`}
                                        key={key} id={`product-${key}`} data-accordion-element="">
                                        <a href={`#product-${key}`} className="gallery__name"
                                           data-accordion-link="" data-only-mobile="true">
                                            {productNameByLang(product)}
                                            <span className="gallery__arrow-icon"><ArrowTop/></span>
                                        </a>
                                        <ul className={`gallery__images`} data-accordion-block="">
                                            <li className="gallery__slider-wrapper">
                                                <Swiper
                                                    direction={'horizontal'}
                                                    pagination={pagination}
                                                    modules={[Pagination]}
                                                    className="mySwiper"
                                                >
                                                    {
                                                        product.images.map((image, key) => {
                                                            return (
                                                                <SwiperSlide key={key} className={"gallery__swiper-slide"}>
                                                                    <img src={"/" + image}
                                                                         alt={productNameByLang(product)}
                                                                         loading='lazy'
                                                                         className="gallery__swiper-slide-image"/>
                                                                </SwiperSlide>
                                                            )
                                                        })
                                                    }
                                                </Swiper>
                                            </li>
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <Footer/>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};

const mapDispatchToProps = {

};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Gallery));