import {
    SET_BREADCRUMBS,
} from '../types'

const initialState = {
    breadcrumbs: [],
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: action.payload ? action.payload : []
            }
        default: return state
    }
}