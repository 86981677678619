import React from "react";
import {localizeRoutes} from "./utils/localizeRoutes";
import Index from "./pages";
import Services from "./pages/services";
import Gallery from "./pages/gallery";
import About from "./pages/about";
import References from "./pages/references";
import Feedback from "./pages/feedback";
import Contacts from "./pages/contact";
import ServiceCategory from "./pages/serviceCategory";
import ServicePage from "./pages/servicePage";

const locales = ['et', 'en', 'fi'];
const routes = [
  {
    path: "/",
    element: <Index/>
  },
  // {
  //   path: "/services",
  //   element: <Services/>
  // },
  {
    path: "/services/:serviceCategorySlug",
    element: <ServiceCategory/>
  },
  {
    path: "/services/:serviceCategorySlug/:serviceSlug",
    element: <ServicePage/>
  },
  {
    path: "/gallery",
    element: <Gallery/>
  },
  {
    path: "/about",
    element: <About/>
  },
  {
    path: "/references",
    element: <References/>
  },
  {
    path: "/feedback",
    element: <Feedback/>
  },
  {
    path: "/contacts",
    element: <Contacts/>
  }
];

export default localizeRoutes(routes, locales);