import * as React from "react";

export default function AbbIcon() {
    return (
        <svg width="183" height="70" viewBox="0 0 183 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M182.304 49.0284C182.151 49.5281 182.179 50.0519 182.099 50.5625C180.806 58.8688 176.373 64.7909 168.691 68.1942C165.864 69.4467 162.864 70.0005 159.767 69.9902C158.77 69.9873 157.774 69.9829 156.779 69.9946C156.558 69.9976 156.483 69.9434 156.491 69.713C156.508 69.2389 156.496 68.7634 156.496 68.2886C156.496 57.7568 156.498 47.2244 156.487 36.6926C156.487 36.34 156.571 36.2566 156.923 36.2573C164.331 36.2683 171.74 36.2676 179.149 36.2595C179.437 36.2595 179.602 36.3385 179.746 36.5982C180.947 38.7608 181.643 41.0857 182.009 43.5204C182.119 44.2542 182.207 44.9916 182.238 45.7341C182.24 45.7919 182.231 45.8578 182.303 45.887V49.0292L182.304 49.0284Z" fill="#ED2125"/>
            <path d="M154.387 0.000488281C154.385 0.134366 154.38 0.267513 154.38 0.401391C154.38 11.4321 154.378 22.4635 154.39 33.4942C154.39 33.8724 154.289 33.9463 153.931 33.9456C147.166 33.9346 140.401 33.9346 133.636 33.9448C133.308 33.9448 133.218 33.8673 133.218 33.5293C133.227 22.4862 133.226 11.443 133.227 0.399928C133.227 0.266781 133.242 0.133635 133.25 0.000488281C140.296 0.000488281 147.341 0.000488281 154.387 0.000488281Z" fill="#ED2125"/>
            <path d="M105.262 0.000731575C105.927 0.129489 106.605 0.155094 107.273 0.276535C112.983 1.3183 117.693 5.80578 118.874 11.5208C120.152 17.7019 118.089 22.7059 113.285 26.663C113.176 26.753 113.061 26.8378 112.926 26.9432C116.137 28.6931 118.942 30.8893 121.113 33.8726C120.974 33.967 120.851 33.9326 120.738 33.9326C113.742 33.9341 106.746 33.9312 99.749 33.9429C99.4114 33.9429 99.3596 33.8375 99.3603 33.5325C99.3684 22.4396 99.3669 11.3475 99.3662 0.254588C99.3662 0.169725 99.3603 0.0848627 99.3574 0C101.326 0 103.293 0 105.261 0L105.262 0.000731575Z" fill="#ED2125"/>
            <path d="M45.0575 0.000488281C44.9933 0.16875 45.1071 0.302629 45.1566 0.441628C49.0718 11.4665 52.992 22.4898 56.9123 33.5132C56.9407 33.5929 56.9699 33.6734 56.9976 33.7532C57.0406 33.8761 57.002 33.939 56.8663 33.9346C56.7817 33.9317 56.6964 33.9346 56.6111 33.9346C49.8462 33.9346 43.0813 33.9324 36.3164 33.9419C35.9853 33.9419 35.8942 33.8622 35.8949 33.5234C35.9044 22.4803 35.9029 11.4379 35.9008 0.394807C35.9008 0.263855 35.9365 0.127782 35.873 0.000488281H36.0189C36.1246 0.124124 36.2683 0.0707195 36.3944 0.0707195C39.1334 0.0736458 41.8715 0.0736458 44.6105 0.0707195C44.7359 0.0707195 44.8795 0.127782 44.9845 0.000488281L45.0575 0.000488281Z" fill="#ED2125"/>
            <path d="M33.7656 0.071451C33.7729 0.204598 33.7861 0.337744 33.7861 0.470891C33.7868 11.4752 33.7853 22.4803 33.7955 33.4847C33.7955 33.8461 33.7197 33.9448 33.3449 33.9441C26.5931 33.9309 19.8413 33.9346 13.0896 33.9346C12.7862 33.9346 12.6844 33.7941 12.784 33.5132C16.7072 22.4803 20.6311 11.4474 24.5513 0.413096C24.598 0.282145 24.6979 0.157045 24.6498 0.000488281H24.7956C24.8889 0.116809 25.0202 0.071451 25.1354 0.071451C28.0122 0.0736458 30.8896 0.0721826 33.7664 0.071451H33.7656Z" fill="#ED2125"/>
            <path d="M97.2443 0.000731575C97.2422 0.0256051 97.2407 0.0504787 97.2385 0.0753522C90.198 0.0753522 83.1567 0.0753522 76.1162 0.0753522C76.1133 0.0504787 76.1103 0.0248735 76.1074 0C83.1531 0 90.1987 0 97.2443 0V0.000731575Z" fill="#F15D41"/>
            <path d="M33.7657 0.071451C30.8889 0.071451 28.0115 0.0729142 25.1347 0.071451C25.0195 0.071451 24.8883 0.116809 24.7949 0.000488281H33.7598C33.762 0.0238987 33.7635 0.0480406 33.7657 0.071451Z" fill="#F15D41"/>
            <path d="M44.9842 0.000488281C44.8799 0.127782 44.7355 0.0707195 44.6101 0.0707195C41.8712 0.0743773 39.133 0.0743773 36.3941 0.0707195C36.2679 0.0707195 36.1243 0.124124 36.0186 0.000488281H44.9835H44.9842Z" fill="#F15D41"/>
            <path d="M156.509 0.0758405C156.506 0.0509669 156.504 0.0253618 156.501 0.000488281C158.517 0.000488281 160.534 0.000488281 162.551 0.000488281C162.541 0.0246302 162.545 0.0465775 162.562 0.06633C162.418 0.0707195 162.273 0.0794984 162.129 0.0794984C160.256 0.0794984 158.382 0.0773036 156.51 0.0758405H156.509Z" fill="#F15D41"/>
            <path d="M99.3669 53.1574C99.3669 47.667 99.3691 42.1758 99.3604 36.6853C99.3604 36.3678 99.418 36.2566 99.768 36.2573C107.201 36.269 114.633 36.2676 122.066 36.2603C122.329 36.2603 122.476 36.3319 122.608 36.5675C123.787 38.6708 124.488 40.9343 124.856 43.3046C125.242 45.7832 125.329 48.2742 124.945 50.7593C123.835 57.9354 120.192 63.4163 113.906 67.0537C110.455 69.0509 106.689 69.9741 102.703 69.9888C101.707 69.9924 100.711 69.9763 99.7155 69.9968C99.418 70.0027 99.3604 69.9083 99.3604 69.6296C99.3684 64.1391 99.3662 58.6479 99.3662 53.1574H99.3669Z" fill="#ED2125"/>
            <path d="M46.8249 36.0642C50.323 36.0642 53.821 36.0679 57.319 36.0569C57.6085 36.0562 57.7303 36.1359 57.8302 36.4176C61.7446 47.4563 65.6699 58.4914 69.5931 69.5264C69.6215 69.6062 69.6485 69.6874 69.6806 69.7657C69.7564 69.9507 69.6828 70.01 69.502 69.9888C69.4538 69.9829 69.405 69.9881 69.3561 69.9881C63.7572 69.9881 58.1576 69.9859 52.5587 69.9954C52.2794 69.9954 52.162 69.9134 52.0731 69.6523C50.4703 64.9409 48.8551 60.2332 47.2559 55.5204C47.1567 55.2293 47.0328 55.1371 46.7265 55.1386C43.2525 55.1503 39.7793 55.1422 36.3053 55.1532C35.9983 55.1539 35.8948 55.0983 35.8955 54.7611C35.9072 48.6612 35.9065 42.5606 35.897 36.4607C35.897 36.1439 35.967 36.054 36.2951 36.0547C39.8055 36.0679 43.3152 36.0627 46.8257 36.0627L46.8249 36.0642Z" fill="#ED2125"/>
            <path d="M22.8937 36.0642C26.3918 36.0642 29.8898 36.0679 33.3878 36.0576C33.6919 36.0569 33.7962 36.1096 33.7954 36.4476C33.7838 42.5474 33.7845 48.648 33.7932 54.7479C33.7932 55.0574 33.7349 55.1583 33.3995 55.1568C29.9138 55.1429 26.4282 55.1517 22.9419 55.1415C22.6699 55.1408 22.5466 55.2095 22.4547 55.4795C20.8548 60.1915 19.2404 64.8992 17.6383 69.6106C17.5435 69.89 17.4283 69.999 17.1125 69.9983C11.5377 69.9866 5.96289 69.9895 0.388067 69.9895C-0.05821 69.9895 -0.0596685 69.9895 0.086903 69.5762C4.01881 58.5177 7.95218 47.4607 11.8746 36.3985C11.9709 36.1271 12.0919 36.0576 12.3617 36.0584C15.8721 36.0671 19.3818 36.0642 22.8923 36.0642H22.8937Z" fill="#ED2125"/>
            <path d="M76.1045 53.0104C76.1045 47.4943 76.1081 41.9775 76.0957 36.4614C76.095 36.1139 76.2007 36.0547 76.5172 36.0547C83.2952 36.0635 90.0732 36.0642 96.852 36.0532C97.1947 36.0532 97.2596 36.1534 97.2596 36.4746C97.2516 47.5075 97.2509 58.5396 97.2618 69.5725C97.2618 69.9376 97.159 69.999 96.8206 69.9983C90.0543 69.9873 83.2886 69.9866 76.5223 69.9998C76.1591 69.9998 76.0964 69.8988 76.0972 69.5586C76.1088 64.0425 76.1052 58.5257 76.1052 53.0097L76.1045 53.0104Z" fill="#ED2125"/>
            <path d="M133.226 53.0133C133.226 47.4972 133.229 41.9812 133.217 36.4651C133.216 36.1191 133.32 36.054 133.64 36.054C140.418 36.0627 147.196 36.0635 153.974 36.0532C154.31 36.0532 154.388 36.1432 154.388 36.4739C154.379 47.506 154.379 58.5382 154.389 69.5703C154.389 69.9346 154.288 69.999 153.948 69.9983C147.183 69.9873 140.417 69.9873 133.651 69.999C133.297 69.999 133.217 69.9105 133.218 69.5615C133.229 64.0455 133.226 58.5294 133.226 53.0133H133.226Z" fill="#ED2125"/>
            <path d="M76.1164 0.0761719C83.1569 0.0761719 90.1982 0.0761719 97.2387 0.0761719C97.2431 0.21005 97.2518 0.343197 97.2518 0.477075C97.2518 11.4836 97.2504 22.4902 97.262 33.4967C97.262 33.8698 97.168 33.9473 96.8078 33.9473C90.0305 33.9356 83.2539 33.9378 76.4766 33.9422C76.2316 33.9422 76.096 33.9349 76.0967 33.6079C76.1069 22.5041 76.1054 11.4002 76.1062 0.295644C76.1062 0.223218 76.1134 0.150061 76.1178 0.077635L76.1164 0.0761719Z" fill="#ED2125"/>
            <path d="M156.508 0.0759167C158.382 0.0773799 160.255 0.078843 162.128 0.0795746C162.272 0.0795746 162.417 0.0707957 162.561 0.0664062C165.767 0.247837 168.621 1.36641 171.067 3.43604C174.651 6.46915 176.437 10.3955 176.358 15.0783C176.277 19.8109 174.17 23.5924 170.538 26.556C170.463 26.6174 170.385 26.6767 170.308 26.736C170.231 26.7952 170.154 26.853 170.04 26.9393C173.253 28.6944 176.067 30.8891 178.229 33.8717C178.095 33.972 177.971 33.9325 177.858 33.9325C170.862 33.9347 163.865 33.9325 156.87 33.9412C156.577 33.9412 156.487 33.8849 156.487 33.5703C156.497 22.5023 156.495 11.4351 156.495 0.367083C156.495 0.269784 156.503 0.173216 156.508 0.0759167H156.508Z" fill="#ED2125"/>
        </svg>

    );
}