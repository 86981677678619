import React from 'react';
import Settings from "./settings";

const Footer = () => {
    return (
        <footer className={'footer'}>
            <Settings className={'settings_footer'}/>
        </footer>
    );
}

export default Footer;