import React from 'react';

const Layout = ({children}) =>{
    return(
        <div className="container container_spacer">
            <div className="container__inner">
                {children}
            </div>
        </div>
    )
}

export default Layout;