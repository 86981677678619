import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from "../components/Layout"
import Header from '../components/Header'
import {useTranslation, withTranslation} from "react-i18next";
import {makeRequest} from "../api/fetcher";
import Breadcrumbs from "../components/breadcrumbs";
import {setBreadcrumbs} from "../actions";
import store from '../../src/store';
import Footer from "../components/footer";
import GrundfosIcon from "../icons/GrundfosIcon";
import {prefixPath} from "../utils/helpers";
import ArrowRight from "../icons/ArrowRight";
import GrundfosSecondIcon from "../icons/GrundfosSecondIcon";
import InnomoticsIcon from "../icons/InnomoticsIcon";
import AbbIcon from "../icons/AbbIcon";
import FemIcon from "../icons/FelmIcon";
import PdfIcon from "../icons/PdfIcon";
import RemontHooldus from "./remontHooldus";

const ServiceCategory = ({ t }) => {
    const { i18n } = useTranslation();
    const locale = i18n.language;
    const { serviceCategorySlug } = useParams();
    const [category, setCategory] = useState([]);

    useEffect(() => {
        const fetchCategory = async () => {
            const category = await makeRequest({
                method: 'get',
                url: `/api/service/${serviceCategorySlug}`
            });

            setCategory(category.data);
            store.dispatch(setBreadcrumbs({'service': category.data}));
        };

        fetchCategory();
    }, [serviceCategorySlug])

    const getServiceName = (service) => {
        return i18n.language === 'et'
            ? service.nameEt
            : i18n.language === 'en'
                ? service.nameEn : service.nameFi;
    }

    const serviceText = (service) => {
        return i18n.language === 'et'
            ? service.nameEt
            : i18n.language === 'en'
                ? service.nameEn : service.nameFi;
    }

    const isListHasOneColumn = (category) => {
        return [
            'diagnostics-of-industrial-equipment',
            'activities-and-measurable-parameters',
            'equipment-and-systems-used',
            'diagnostic-moto-and-catchphrases'
        ].includes(category.slug);
    }

    if (category.status === 'error') {
        return <Navigate to="/"/>
    }

    return (
        <Layout>
            <Header/>
            <div className="layout__inner layout__inner_gallery">
                <Breadcrumbs/>
                <h1 className="layout__h1">
                    {getServiceName(category)}
                </h1>
                <div className="service-category">
                    {
                        category.descriptionKey
                        ?
                            <div className="service-category__description" dangerouslySetInnerHTML={{__html: t(`${category.descriptionKey}`) }}>
                            </div>
                        : ''
                    }

                    {
                        category.titleKey
                        ?
                            <div className={`service-category__title ${category.slug === 'sale-of-new-electric-motors' ? '_without-space' : ''}`} dangerouslySetInnerHTML={{__html: t(`${category.titleKey}`) }}>
                            </div>
                        : ''
                    }

                    <div className={`service-category__lists-container 
                    ${category.secondListKey === undefined || category.secondListKey === '' ? '_full': ''}  
                    ${category.slug === 'fieldwork-on-the-clients-object' ? '_fit-content': ''}  
                    ${category.slug === 'balancing' ? '_fit-content': ''}  
                    ${category.slug === 'thermographic-analysis' ? '_fit-content': ''}  
                    ${category.slug === 'alignment' ? '_fit-content': ''}  
                    ${isListHasOneColumn(category) ? '_one-column' : ''}`
                    }>
                        {
                            category.firstListKey
                                ?
                                <div className={`service-category__list-outer`} dangerouslySetInnerHTML={{__html: t(`${category.firstListKey}`) }}>
                                </div>
                                : ''
                        }

                        {
                            category.secondListKey
                                ?
                                <div className="service-category__list-outer" dangerouslySetInnerHTML={{__html: t(`${category.secondListKey}`) }}>
                                </div>
                                : ''
                        }

                        {
                            category.slug === 'grandfos-authorized-service-partner'
                                ?
                                <div className="service-category__grundfos-icons">
                                    <a href="https://www.grundfos.com" title="Grundfos" target="_blank" rel="nofollow" className="service-category__grundfos-icon">
                                        <GrundfosIcon/>
                                    </a>
                                    <a href="https://gf.idsm.eu/grundfos_partners/s/elektrimasinad/start/" title="Grundfos" target="_blank" rel="nofollow" className="service-category__grundfos-icon">
                                        <GrundfosSecondIcon/>
                                    </a>
                                </div>
                                : ''
                        }

                        {
                            category.slug === 'maintenance-contracts'
                                ?
                                <div className="service-category__complete-solution">
                                    <div className="service-category__percent">100%</div>
                                    <div className="service-category__complete-solution-text">{t('complete_solution')}</div>
                                </div>
                                : ''
                        }

                        {
                            category.slug === 'fieldwork-on-the-clients-object'
                                ?
                                <div>
                                    <div className="service-category__complete-solution">
                                        <div className="service-category__complete-solution-text service-category__complete-solution-text_tiny">{t('fieldwork-extra-text')}</div>
                                    </div>
                                    <a href={prefixPath("/gallery", locale)} className="repair__item repair__item_arrow" style={{marginTop: "30px"}}>
                                        {t('vaata_pilte')}
                                        <span className="service-category__icon-wrapper">
                                            <ArrowRight/>
                                        </span>
                                    </a>
                                </div>
                                : ''
                        }

                        {
                            category.slug === 'alignment'
                                ?
                                <div>
                                    <a href='/static/images/base/docs/alignment_doc.jpg' target={"_blank"} className="repair__item repair__item_arrow" style={{marginTop: "30px"}}>
                                        {t('vaata_pilte')}
                                        <span className="service-category__icon-wrapper">
                                            <ArrowRight/>
                                        </span>
                                    </a>
                                </div>
                                : ''
                        }

                        {
                            category.slug === 'thermographic-analysis'
                                ?
                                <div>
                                    <a href='/static/images/base/docs/termograafiline_analüüs.jpg' target={"_blank"} className="repair__item repair__item_arrow" style={{marginTop: "30px"}}>
                                        {t('vaata_pilte')}
                                        <span className="service-category__icon-wrapper">
                                            <ArrowRight/>
                                        </span>
                                    </a>
                                </div>

                                : ''
                        }

                        {
                            category.slug === 'sale-of-new-electric-motors'
                                ?
                                <ul className="service-category__elektromotori-partner-list">
                                    <li className="service-category__elektromotori-item">
                                        <div className="service-category__elektromotori-top">
                                            <div className="service-category__elektromotori-icon-wrapper">
                                                <InnomoticsIcon/>
                                            </div>
                                            <div className="service-category__elektromotori-title">{t('elektromotori_title_1')}</div>
                                        </div>
                                        <a href="#" className="service-category__elektromotori-bottom-link">
                                            <span className="service-category__elektromotori-bottom-link-text">{t('elektromotori_link_1')}</span>
                                            <span className="service-category__elektromotori-bottom-link-icon"><PdfIcon/></span>
                                        </a>
                                    </li>
                                    <li className="service-category__elektromotori-item">
                                        <div className="service-category__elektromotori-top">
                                            <div className="service-category__elektromotori-icon-wrapper">
                                                <AbbIcon/>
                                            </div>
                                            <div className="service-category__elektromotori-title">{t('elektromotori_title_2')}</div>
                                        </div>
                                        <a href="#" className="service-category__elektromotori-bottom-link">
                                            <span className="service-category__elektromotori-bottom-link-text">{t('elektromotori_link_2')}</span>
                                            <span className="service-category__elektromotori-bottom-link-icon"><PdfIcon/></span>
                                        </a>
                                    </li>
                                    <li className="service-category__elektromotori-item">
                                        <div className="service-category__elektromotori-top">
                                            <div className="service-category__elektromotori-icon-wrapper">
                                                <FemIcon/>
                                            </div>
                                            <div className="service-category__elektromotori-title">{t('elektromotori_title_3')}</div>
                                        </div>
                                        <a href="#" className="service-category__elektromotori-bottom-link">
                                            <span className="service-category__elektromotori-bottom-link-text">{t('elektromotori_link_3')}</span>
                                            <span className="service-category__elektromotori-bottom-link-icon"><PdfIcon/></span>
                                        </a>
                                    </li>
                                </ul>
                                : ''
                        }

                        {
                            category.slug === 'repair-and-maintenance-of-electric-motors'
                                ?
                                <RemontHooldus/>
                                : ''
                        }
                    </div>

                    {
                        category.slug === 'diagnostics-of-industrial-equipment'
                            ?
                            <div className="service-category__diagnostics-text">{t('diagnostika_first_key')}</div>
                        : ''
                    }

                    {
                        category.childServices && Object.keys(category.childServices).length &&
                            category.slug !== 'repair-and-maintenance-of-electric-motors'
                        ?
                            <ul className="service-category__child-list">
                                {
                                    Object.values(category.childServices).map((id, key) => (
                                        <li className="service-category__child-item" key={key}>
                                            <Link to={prefixPath(`/services/${category.slug}/${id.slug}`, locale)}
                                                  className="service-category__child-link">
                                                {getServiceName(id)}
                                                <span className="service-category__icon-wrapper">
                                                    <ArrowRight/>
                                                </span>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        : ''
                    }

                    {
                        category.slug === 'diagnostics-of-industrial-equipment'
                            ?
                            <div className="service-category__diagnostics-text">{t('diagnostika_second_key')}</div>
                            : ''
                    }

                    {
                        category.slug === 'balancing'
                            ?
                            <div style={{marginTop: "20px"}} dangerouslySetInnerHTML={{__html: t('tasakaalustamine_third_list_key') }}></div>
                            : ''
                    }

                    {
                        category.slug === 'balancing'
                            ?
                            <div className="service-category__complete-solution service-category__complete-solution_adaptive">
                                <div className="service-category__percent">{t('tasakaalustamine_solution_left')}</div>
                                <div className="service-category__complete-solution-text">{t('tasakaalustamine_solution_right')}</div>
                            </div>
                            : ''
                    }
                </div>
            </div>
            <Footer/>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};

const mapDispatchToProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ServiceCategory));